<template>
  <b-card no-body v-for="guide in guidesStore.guides" :key="guide.id" class="mb-9">
    <div class="card-header info-guide-header">
      <div class="card-title">
        <h3>{{ guide.title }}</h3>
      </div>
    </div>
    <div class="card-body">
      <pre class="info-guide-content-text"><span v-html="guide.sanitizeContent" /></pre>
    </div>
  </b-card>
</template>
<script lang="ts" setup>
import { useGuidesStore } from "@/stores/shop/guides";

const guidesStore = useGuidesStore();

guidesStore.fetchGuides();
</script>
