<template>
  <div v-if="subsOrder">
    <nav-bar-subs-order-detail :active="`item`" :number="props.number" />
    <subs-order-details
      :subs-order="subsOrder"
      :message-update="message"
      @cancel-subs-order="cancelSubsOrder"
      @reactive-subs-order="reactiveSubsOrder"
    />
  </div>
</template>

<script lang="ts" setup>
import { useSubsOrdersStore } from "@/stores/customer/subsOrders";

import { computed, onBeforeMount } from "vue";
import NavBarSubsOrderDetail from "@/components/molecules/common/layout/NavBarSubsOrderDetail.vue";
import SubsOrderDetails from "@/components/organisms/pages/customer/subs_orders/SubsOrderDetails.vue";

interface Props {
  number: string;
}

const props = defineProps<Props>();

const subsOrdersStore = useSubsOrdersStore();

subsOrdersStore.fetchSubsOrder(props.number);

const subsOrder = computed(() => subsOrdersStore.subsOrder);
const message = computed(() => subsOrdersStore.messageUpdate);

onBeforeMount(() => {
  if (!subsOrdersStore.keepMessage) subsOrdersStore.messageUpdate = null;

  subsOrdersStore.keepMessage = false;
  subsOrdersStore.fetchSubsOrder(props.number);
});

function cancelSubsOrder() {
  subsOrdersStore.cancelSubsOrder(props.number);
}

function reactiveSubsOrder(scheduledToBeDeliveredAt: string) {
  subsOrdersStore.reactiveSubsOrder(props.number, scheduledToBeDeliveredAt);
}
</script>
