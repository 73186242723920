import { BaseInput } from "../BaseInput";
import { SharedAddressInput } from "../shared/AddressInput";

export interface CustomersBillingAddressInputType extends SharedAddressInput {}

export class CustomersBillingAddressInput
  extends BaseInput
  implements CustomersBillingAddressInputType
{
  constructor(
    public name01 = "",
    public name02 = "",
    public kana01 = "",
    public kana02 = "",
    public zip01 = "",
    public zip02 = "",
    public prefectureId = '',
    public addr01 = "",
    public addr02 = "",
    public tel01 = "",
    public tel02 = "",
    public tel03 = "",
  ) {
    super();
  }
}
