export const publicProducts = `
  query($input: PagyInput!) {
    publicProducts(input: $input) {
      metadata
      collection {
        masterListPrice
        masterSalesPrice
        name
        productUrl
        thumbnailUrl
      }
    }
  }
`;
