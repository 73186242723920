<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="py-3">変更する場合は、一番下の「保存」ボタンをクリックしてください。</div>

      <b-card class="mb-3">
        <template #header>パスワード変更</template>

        <div class="d-grid gap-3">
          <FormValidator label="現在のパスワード" name="current_password" required float>
            <b-input
              v-model="current_password"
              placeholder="半角英数字8文字以上（大文字小文字をそれぞれ含む）"
              type="password"
            ></b-input>
          </FormValidator>

          <FormValidator label="新しいパスワード" name="new_password" required float>
            <b-input
              v-model="new_password"
              placeholder="半角英数字8文字以上（大文字小文字をそれぞれ含む）"
              type="password"
            ></b-input>
          </FormValidator>

          <FormValidator label="パスワード確認用" name="new_password_confirmation" required float>
            <b-input
              v-model="new_password_confirmation"
              placeholder="パスワード確認用"
              type="password"
            ></b-input>
          </FormValidator>

          <div class="text-danger">確認のため2度入力してください。</div>
        </div>
      </b-card>

      <div class="text-center mb-5">
        <b-button size="lg" variant="success" type="submit">保存</b-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import * as authQueries from "@/graphql/queries/customers/auth";
import useGraphql from "@/composables/useGraphql";
import { CommonType } from "@/types/customers/CommonType";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useValidationStore } from "@/stores/form/validation";

const {
  handleSubmit,
  validate,
  values: formValues,
} = useForm({
  validationSchema: yup.object({
    current_password: yup.string().required().min(8),
    new_password: yup.string().required().min(8),
    new_password_confirmation: yup
      .string()
      .required()
      .min(8)
      .oneOf([yup.ref("new_password")], "入力された値が一致しません"),
  }),
  initialValues: {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  },
});

const validationStore = useValidationStore();

const submitForm = async () => {
  validationStore.submitAttempts++;

  const result = await validate();

  if (!result.valid) return;

  const values = formValues;

  const params = {
    currentPassword: values.current_password,
    newPassword: values.new_password,
    newPasswordConfirmation: values.new_password_confirmation,
  };
  useGraphql<CommonType>(
    authQueries.customersUpdatePassword,
    { input: params },
    { toasted: true },
  ).then((result) => {
    setTimeout(() => {
      window.location.href = "/shop/customer";
    }, 1000);
  });
};

const { value: current_password } = useField("current_password");
const { value: new_password } = useField("new_password");
const { value: new_password_confirmation } = useField("new_password_confirmation");
</script>
