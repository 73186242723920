<template>
  <div class="login-form">
    <customer-sign-in type="shop"></customer-sign-in>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";

import { useAuthStore } from "@/stores/auth";
import { useBaseInfoStore } from "@/stores/base_info";

import CustomerSignIn from "@/components/organisms/pages/customer/CustomerSignIn.vue";

import { useTitle } from "@vueuse/core";

const authStore = useAuthStore();

const baseInfoStore = useBaseInfoStore();

const baseInfo = computed(() => baseInfoStore.baseInfo);

if (authStore.isLoggedIn) {
  window.location.href = authStore.originalUrl ? authStore.originalUrl : "/shop/customer";

  authStore.clearOriginalUrl();
}

watch(
  baseInfo,
  (value) => {
    if (value) {
      useTitle(value?.shopName);
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.login-form {
  max-width: 700px;
  margin: auto;
  margin-top: 40px;
}
</style>
