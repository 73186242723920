import { BaseInput } from "../BaseInput";
import { SharedCustomerInput } from "../shared/CustomerInput";
import { CustomersBillingAddressInput } from "./BillingAddressInput";

export interface CustomersUpdateInputType extends SharedCustomerInput {
  billingAddressAttributes: CustomersBillingAddressInput;
  lockVersion: number;
}

export class CustomersUpdateInput extends BaseInput implements CustomersUpdateInputType {
  constructor(
    public birthday = null,
    public optin = false,
    public sexId = null,
    public billingAddressAttributes = new CustomersBillingAddressInput(),
    public lockVersion = 0,
  ) {
    super();
  }
}
