<template>
  <div v-if="customerStore.customerInfo">
    <Notice>
      <template #icon>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
            fill="currentColor"
          ></path>
          <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor"></rect>
        </svg>
      </template>
      お客様の会員情報をご変更いただけます。支払い方法が後払いの場合、請求書はこちらでご登録の住所に郵送されます。<br />
      ※
      既にご注文をいただいている商品、定期購入商品に関するお届け先等のご変更は購入履歴、または定期管理でご確認ください。
    </Notice>

    <FormCustomer></FormCustomer>
  </div>
</template>

<script lang="ts" setup>
import { useCustomerStore } from "@/stores/customer/edit";
import FormCustomer from "@/components/molecules/pages/customer/edit/FormCustomer.vue";

const customerStore = useCustomerStore();
</script>
