<template>
  <b-card>
    <template #header>
      <div class="pt-4">
        <blockquote>パスワードを再設定する</blockquote>
      </div>
    </template>
    <p>新しく設定したいパスワードを入力してください。</p>
    <b-form accept-charset="UTF-8" @submit.prevent="submitForm">
      <div class="form-group">
        <form-validator name="password" required label="パスワード" float>
          <input
            v-model="password"
            type="password"
            class="form-control input-sm"
            placeholder="半角英数字8文字以上（大文字小文字をそれぞれ含む）"
          />
        </form-validator>
        <form-validator name="password_confirmation" required label="パスワード（確認）" float>
          <input
            v-model="password_confirmation"
            type="password"
            class="form-control input-sm"
            placeholder="パスワード確認用"
          />
        </form-validator>

        <p class="mt-3">※ 確認のため、同じパスワードをもう一度入力してください</p>
      </div>
      <br />
      <div class="d-flex justify-content-center">
        <input
          class="btn btn-sm btn-success"
          style="width: 320px; height: 40px; font-size: inherit"
          type="submit"
          value="パスワードを再設定する"
        />
      </div>
    </b-form>
  </b-card>
</template>

<script setup lang="ts">
import * as authQueries from "@/graphql/queries/customers/auth";
import useGraphql from "@/composables/useGraphql";
import { useAuthStore } from "@/stores/auth";
import { CustomersPasswordUpdateType } from "@/types/customers/CustomersPasswordUpdateType.js";
import { useValidationStore } from "@/stores/form/validation";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";

const authStore = useAuthStore();

const getTokenFromUrl = () => {
  const url = window.location.href;

  const tokenIndex = url.indexOf("token=");
  if (tokenIndex === -1) {
    return null;
  }
  const token = url.slice(tokenIndex + 6);

  return token;
};

const { values: formValues, validate } = useForm({
  validationSchema: yup.object({
    password: yup.string().min(8).required("必須項目です & 8文字以上にしてください"),
    password_confirmation: yup
      .string()
      .min(8)
      .oneOf([yup.ref("password")], "入力された値が一致しません")
      .required(),
  }),
  initialValues: {
    password: "",
    password_confirmation: "",
  },
});

const validationStore = useValidationStore();

const submitForm = async () => {
  validationStore.submitAttempts++;

  const result = await validate();

  if (!result.valid) return;

  const values = formValues;

  const params = {
    password: values.password,
    passwordConfirmation: values.password_confirmation,
  };

  useGraphql<CustomersPasswordUpdateType>(
    authQueries.customersPasswordResetFormSubmit,
    { input: params, token: getTokenFromUrl() },
    { toasted: true },
  ).then((response) => {
    authStore.setTokenAndCookie(response.token);

    setTimeout(() => {
      window.location.href = "/shop/customer";
    }, 1000);
  });
};
const { value: password } = useField("password");
const { value: password_confirmation } = useField("password_confirmation");
</script>

<style scoped>
label {
  font-size: inherit;
  font-weight: bold;
}
</style>
