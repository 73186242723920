<template>
  <div>
    <b-card>
      <blockquote class="d-flex flex-direction-row justify-content-between">
        <a>新着ニュース</a>
        <a
          :href="`/shop/information`"
          class="float-right"
        >
          ニュース一覧へ
        </a>
      </blockquote>
      <div
        class="mb-9 pb-0"
        v-for="information in informationsStore.informations"
        :key="information.slug"
      >
        <div class="card-body d-flex">
          <div class="col-md-4">
            <img
              v-if="!information.thumbnailUrl"
              src="@/assets/images/shop/defaultImg.png"
              alt="default-image"
            />
            <img
              v-else
              :src="information.thumbnailUrl"
              alt="upload-image"
            />
          </div>
          <div class="align-items-center col-8">
            <p style="font-size: large">
              <a :href="`/shop/information/${information.slug}`">
                {{ information.title }}
              </a>
            </p>
            <div class="d-flex text">
              <p class="mx-2">
                {{ filters.jpDateTimeWithText(information.publishedAt) }}
              </p>
              |
              <a
                class="mx-2"
                :href="`/shop/information_categories/${information.informationCategory.slug}`"
              >
                {{ information.informationCategory.name }}
              </a>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </b-card>
  </div>
</template>
<script lang="ts" setup>
import { useInformationsStore } from "@/stores/shop/informations";
import filters from "@/composables/filters";

const informationsStore = useInformationsStore();

informationsStore.fetchInformationInShop();
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  font-size: 15px;
}

.card .card-body {
  padding: 0;
  gap: 15px;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  font-size: 14px;
  padding: 10.5px 21px;
  margin: 0 0 21px;
  font-size: 18.75px;
  border-left: 5px solid #e6e6e6;
}

.text-show-item {
  margin-top: 10px;
  font-size: 0.75vw;
}
</style>
