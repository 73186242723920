<template>
  <div class="card-toolbar text-gray-700 mb-2">
    <strong class="text-danger">{{ subsOrdersStore.metadata.count }}</strong> 件該当しました
  </div>
  <Notice>
    定期でご購入いただいている商品の一覧です。<br />
    次回以降の情報を変更する場合は、該当する定期注文の詳細へお進みください。<br />
    <span class="text-danger">
      ※定期情報の変更/定期の解約をする場合は、次回配送予定日の11日前までに行ってください。過ぎた場合は、次々回分からの受注に対して変更が反映
    </span>
  </Notice>

  <b-card no-body footer-class="d-flex align-items-center justify-content-between py-5">
    <div class="table-responsive">
      <table
        class="table table-flush align-middle table-row-bordered table-row-solid gy-4 gx-2 gs-9 mb-0"
      >
        <thead class="fs-7 fw-bold text-uppercase">
          <tr>
            <th>定期番号</th>
            <th>ステータス</th>
            <th>小計(税抜)</th>
            <th>作成日</th>
            <th>定期内受注数</th>
            <th>配送サイクル</th>
            <th>次回配送予定日</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="fs-6 text-gray-600">
          <tr v-for="subsOrder in subsOrdersStore.subsOrders" :key="subsOrder.id">
            <td>
              <a :href="`/shop/customer/subs_orders/${subsOrder.number}`">
                {{ subsOrder.number }}
              </a>
            </td>
            <td>{{ subsOrder.stateI18n }}</td>
            <td>{{ subsOrder.subtotal }}</td>
            <td>{{ filters.fullDateTime(subsOrder.createdAt) }}</td>
            <td>{{ subsOrder.orders.length }}</td>
            <td>{{ subsOrder.fullRecreateOrderDetail }}</td>
            <td>{{ subsOrder.scheduledToBeDeliveredAt }}</td>
            <td>
              <a :href="`/shop/customer/subs_orders/${subsOrder.number}`"> 詳細 </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-6 mt-2 ml-4 mb-4" v-if="subsOrdersStore.metadata.pages == 1">
        <strong class="ml-4">{{ subsOrdersStore.metadata.count }}</strong>
        件表示中です
      </div>
      <div class="col-md-6mt-2 ml-4 mb-4" v-else>
        <strong class="ml-4">{{ subsOrdersStore.metadata.count }}</strong>
        件中
        <strong class="ml-4"
          >{{ subsOrdersStore.metadata.from }} - {{ subsOrdersStore.metadata.to }}</strong
        >
        件を表示しています
      </div>
    </div>

    <template #footer>
      <b-pagination
        v-model="pagy.page"
        :per-page="pagy.perPage"
        :total-rows="subsOrdersStore.metadata.count"
        first-text="最初へ"
        last-text="最後へ"
        @page-click="fetchSubsOrders"
      ></b-pagination>
    </template>
  </b-card>
</template>

<script lang="ts" setup>
import { useSubsOrdersStore } from "@/stores/customer/subsOrders";
import filters from "@/composables/filters"

const subsOrdersStore = useSubsOrdersStore();

subsOrdersStore.fetchSubsOrders();
const { pagy } = subsOrdersStore;

function fetchSubsOrders() {
  subsOrdersStore.fetchSubsOrders();
}
</script>

<style lang="scss" scoped>
.table-responsive {
  th,
  td {
    white-space: nowrap;
    font-size: 12px;
  }
}
</style>
