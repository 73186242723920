<template>
  <div v-if="baseInfo?.enableShopProductList">
    <div class="search-action">
      <div class="search-input">
        <b-input v-model="searchValue" />
      </div>

      <div class="search-btn-container">
        <b-button class="float-end search-btn" variant="primary" @click="onSearch"> 検索 </b-button>
      </div>
    </div>

    <b-card>
      <div class="text-start text-show-item">
        <b class="text-danger">
          {{ pagination.count }}
        </b>
        件該当しました
      </div>

      <ProductCard v-for="product in productsStore.products" :product="product" />

      <div class="text-left">
        <div class="col-md-6 mt-5 ml-4 mb-4" v-if="pagination.pages <= 1">
          <strong class="ml-4">{{ pagination.count }}</strong
          >件表示中です
        </div>

        <div class="col-md-6 mt-5 ml-4 mb-4" v-else>
          <strong class="ml-4">
            {{ pagination.count }}
          </strong>
          件中
          <strong class="ml-4"> {{ pagination.from }} - {{ pagination.to }} </strong>
          件を表示しています
        </div>
      </div>

      <template #footer>
        <b-pagination
          v-if="pagination.pages > 1"
          v-model="pagy.page"
          :per-page="pagy.perPage"
          :total-rows="pagination.count"
          first-text="最初へ"
          last-text="最後へ"
          @page-click="productsStore.fetchProducts()"
        />
      </template>
    </b-card>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import { useProductsStore } from "@/stores/shop/products";
import { useBaseInfoStore } from "@/stores/base_info";

import ProductCard from "@/components/organisms/pages/shop/products/ProductCard.vue";
const productsStore = useProductsStore();

const pagination = computed(() => productsStore.metadata);
const { pagy } = productsStore;

productsStore.fetchProducts();

const searchValue = ref("");

const onSearch = () => {
  pagy.page = 1;
  pagy.q = { name_cont: searchValue.value };
  productsStore.fetchProducts();
};

const baseInfoStore = useBaseInfoStore();

const baseInfo = computed(() => baseInfoStore.baseInfo);

document.addEventListener("keypress", function (e) {
  if (e.key !== "Enter") return;

  onSearch();
});
</script>

<style lang="scss" scoped>
:deep(.card-body) {
  padding-bottom: 0px;
}
.search-btn {
  max-height: 39px;
}
.search-action {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .search-input {
    width: 90%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .search-btn-container {
    width: 10%;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 5px;
    }
  }
}

@media (max-width: 768px) {
  .search-action {
    flex-direction: column;
    .search-input {
      width: 100%;
    }

    .search-btn-container {
      width: 100%;
      margin-top: 5px;

      .search-btn {
        width: 100%;
      }
    }
  }
}
</style>
