import moment from "moment";
import { date } from "yup";

export default {
  fullDateTime: (date) => {
    return date ? moment(date).parseZone().format("YYYY/MM/DD HH:mm:ss") : "";
  },

  jpDateTimeWithText: (date) => {
    return date ? moment(date).parseZone().format("YYYY年 MM月 D日 HH:mm") : "";
  },

  currency: (number: number) => {
    if (!number) return "0円";

    const formatter = new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "code",
    });

    const tmp = formatter.format(number).replace("JPY", "").trim();
    return `${tmp}円`
  }
};
