<template>
  <div>
    <nav-bar-subs-order-detail :active="`address`" :number="props.number" />
    <subs-order-address :address="subsOrder?.shippingAddress" :number="props.number" />
  </div>
</template>

<script lang="ts" setup>
import NavBarSubsOrderDetail from "@/components/molecules/common/layout/NavBarSubsOrderDetail.vue";
import { useSubsOrdersStore } from "@/stores/customer/subsOrders";
import { computed } from "vue";

import SubsOrderAddress from "@/components/organisms/pages/customer/subs_orders/SubsOrderAddress.vue";

interface Props {
  number: string;
}

const props = defineProps<Props>();

const subsOrdersStore = useSubsOrdersStore();

subsOrdersStore.fetchSubsOrder(props.number);

const subsOrder = computed(() => subsOrdersStore.subsOrder);
</script>
