<template>
  <b-card footer-class="d-flex justify-content-end py-6 px-9">
    <template #header>
      <div class="card-title m-0">
        <h3 class="fw-bold m-0">お客様情報</h3>
      </div>
    </template>

    <template #footer>
      <button class="btn btn-light btn-active-light-primary me-2" onclick="history.back()">
        戻る
      </button>
      <button class="btn btn-primary" @click="onSubmit">保存</button>
    </template>

    <p>変更する場合は、一番下の「保存」ボタンをクリックしてください。</p>

    <Notice> 必須項目 「 ※ 」印は入力必須項目です。 </Notice>

    <div class="d-grid gap-5">
      <FormAddress
        :addressData="billingAddressAttributes"
        prefix="billingAddressAttributes"
        prefixNameValidator="billing_address_data"
      ></FormAddress>
      <!--
      <form-validator label="メールアドレス"  required>
        <b-input
          v-model="billingAddressAttributes.email"
          placeholder="例）○○○@example.com"
          type="email"
        ></b-input>
      </form-validator> -->

      <form-validator label="性別" name="sex_id" clientName="sexId" float>
        <b-select v-model="sexId" :options="customerStore.sexes">
          <template #first>
            <option :value="null">選択してください</option>
          </template>
        </b-select>
      </form-validator>

      <form-validator label="生年月日" name="birthday" clientName="birthday" float>
        <b-input v-model="birthday" type="date"></b-input>
      </form-validator>

      <!-- <form-validator label="メールマガジン受信">
        <b-checkbox v-model="optin" switch></b-checkbox>
      </form-validator> -->
    </div>
  </b-card>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useCustomerStore } from "@/stores/customer/edit";
import { cloneDeep } from "lodash";
import { useValidationStore } from "@/stores/form/validation";
import {
  addressDataSchemaFragment,
  customerBasicInfoSchemaFragment,
} from "@/composables/useCartValidation";
import FormAddress from "@/components/molecules/pages/cart/FormAddress.vue";

const customerStore = useCustomerStore();
const validationStore = useValidationStore();

const {
  values: formValues,
  setValues: setFormValues,
  validate,
} = useForm({
  validationSchema: yup.object({
    billingAddressAttributes: yup.object(addressDataSchemaFragment),
    ...customerBasicInfoSchemaFragment,
  }),
  initialValues: customerStore.customerInfo,
});

onMounted(async () => {
  await customerStore.fetchCustomerInfo();
  setFormValues(customerStore.customerInfo);
  sexId.value = customerStore.sexes.find((item) => item.value == sexId.value) ? sexId.value : null;
});

const onSubmit = async () => {
  validationStore.submitAttempts++;

  const result = await validate();

  if (!result.valid) return;

  customerStore.customerInfo = cloneDeep(formValues);
  customerStore.updateCustomer();
};

const { value: billingAddressAttributes }: any = useField("billingAddressAttributes");
const { value: sexId } = useField("sexId");
const { value: birthday } = useField("birthday");
const { value: optin } = useField("optin");
</script>
