<template>
  <b-card>
    <div class="col-md-9">
      <p>お問い合わせありがとうございます。</p>
      <p>お客様のお問い合わせ番号は {{ data.number }} です。</p>
      <p>数分以内に確認メールが {{ data.email }} 宛に送信されます。</p>
      <p>こちらのページは60秒後に再度アクセスするとお問い合わせトップへ移動します。</p>
      <br />
    </div>

    <table-show :data="data"> </table-show>
    <div class="d-flex justify-content-between mt-3">
      <a href="/shop/contact/draft" class="btn btn-sm btn-dark">戻る</a>
    </div>
  </b-card>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useContactStore } from "@/stores/contacts";
import { ContactInput } from "@/types/input/contactInput";
import moment from "moment";
import TableShow from "@/components/molecules/pages/contact/TableShow.vue";

interface Props {
  id: number | string;
}

const props = defineProps<Props>();
const contactStore = useContactStore();
const data = computed(() => contactStore.contact);

onMounted(() => {
  contactStore.contactInput = new ContactInput();
  contactStore.confirming = false;

  if (moment().unix() - contactStore.timeStaging > 60) {
    contactStore.$reset();
    window.location.href = `/shop/contact/draft`;
  }
});
</script>

<style lang="scss" scoped>
.card {
  p {
    margin-bottom: 5px;
  }
}
</style>
