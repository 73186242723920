export interface BaseInputType {
  assignAttributes(input: any): void;
}

export class BaseInput implements BaseInputType {
  assignAttributes(input: any) {
    if (input) {
      Object.keys(this).forEach((key) => {
        if (!(this[key] instanceof BaseInput)) {
          this[key] = input[key];
        }
      });
    }
  }
}
