export const information = `
query ($slug: String!, $userAgent: String!) {
  information (slug: $slug, userAgent: $userAgent) {
      contentDevice          
      title
      thumbnailUrl
      publishedAt
      informationCategory {
          name
          slug
      }
      nextInformation {
        slug
        title
      }
      prevInformation {
        slug
        title
      }
    }
  }   
`;

export const informations = `
query ($input: PagyInput!){
  informations(input: $input) {
    collection {
      slug
      content               
      title
      thumbnailUrl
      publishedAt
      informationCategory {
        name
        slug
      }
    } 
    metadata
    }
  }
`;
