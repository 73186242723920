<template>
  <div>
    <b-card class="mb-9 pb-0">
      <div class="card-header info-information-header">
        <div class="card-title">
          <blockquote>
            <a href="/shop/information">ニュース一覧 </a> ＞
            <a>{{ informationCategoryName }}</a>
          </blockquote>
        </div>
      </div>
      <div class="card-body d-flex" v-for="information in informationCategories">
        <div class="col-md-4">
          <img
            v-if="!information.thumbnailUrl"
            src="@/assets/images/shop/defaultImg.png"
            alt="default-image"
          />
          <img v-else :src="information.thumbnailUrl" alt="upload-image" />
        </div>
        <div class="align-items-center col-md-8 px-10">
          <p style="font-size: large">
            <a :href="`/shop/information/${information.slug}`"> {{ information.title }}</a>
          </p>
          <br />
          <div class="d-flex text">
            <p class="mx-2">
              {{ filters.jpDateTimeWithText(information.publishedAt) }}
            </p>
            |
            <a
              class="mx-2"
              :href="`/shop/information_categories/${information.informationCategory.slug}`"
            >
              {{ information.informationCategory.name }}</a
            >
          </div>
        </div>
      </div>
      <hr />
      <b-pagination
        v-model="pagy.page"
        :per-page="pagy.perPage"
        :total-rows="paginations.count"
        class="justify-content-center"
      ></b-pagination>
    </b-card>
    <div class="text-start text-show-item">
      <b class="text-danger"> {{ paginations.items }} </b> 件表示中です
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { useInformationCategoriesStore } from "@/stores/shop/information_categories";
import { get } from "lodash";
import filters from "@/composables/filters"

const prop = defineProps(["slug"]);

const informationCategoriesStore = useInformationCategoriesStore();

const paginations = computed(() => informationCategoriesStore.metadata);

const informationCategories = computed(() => informationCategoriesStore.informationCategories);

const informationCategoryName = computed(() =>
  get(informationCategories.value[0], "informationCategory.name"),
);

const { pagy } = informationCategoriesStore;

informationCategoriesStore.fetchInformationCategories(prop.slug);
</script>
<style scoped>
img {
  width: 80%;
}

.text {
  font-size: 15px;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  font-size: 14px;
  padding: 10.5px 21px;
  margin: 0 0 21px;
  font-size: 18.75px;
  border-left: 5px solid #e6e6e6;
}

.text-show-item {
  margin-top: 10px;
  font-size: 0.75vw;
}
</style>
