<template>
  <div>
    <nav-bar-subs-order-detail :active="`purchass_information`" :number="props.number" />
    <b-card no-body footer-class="d-flex align-items-center justify-content-between py-5">
      <div class="table-responsive">
        <table
          class="table table-flush align-middle table-row-bordered table-row-solid gy-4 gx-2 gs-9 mb-0"
        >
          <thead class="fs-7 fw-bold text-uppercase">
            <tr>
              <th>購入番号</th>
              <th>支払い方法</th>
              <th>分割回数</th>
              <th>作成日</th>
            </tr>
          </thead>
          <tbody class="fs-6 text-gray-600">
            <tr v-for="(order, index) in subsOrder?.orders" :key="index">
              <td>
                <a :href="`/shop/customer/orders/${order.number}`">
                  {{ order.number }}
                </a>
              </td>
              <td>{{ order.paymentMethod }}</td>
              <td>1</td>
              <td>{{ filters.fullDateTime(order.orderedAt) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <div class="mt-5">
      <a href="/shop/customer/subs_orders/" class="btn btn-dark">戻る</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavBarSubsOrderDetail from "@/components/molecules/common/layout/NavBarSubsOrderDetail.vue";
import filters from "@/composables/filters";

import { useSubsOrdersStore } from "@/stores/customer/subsOrders";
import { computed } from "vue";

interface Props {
  number: string;
}

const props = defineProps<Props>();

const subsOrdersStore = useSubsOrdersStore();

subsOrdersStore.fetchSubsOrder(props.number);

const subsOrder = computed(() => subsOrdersStore.subsOrder);
</script>
