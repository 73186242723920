<template>
  <div>
    <b-card v-if="messageUpdate" class="my-3 update-notice">{{ messageUpdate }}</b-card>
    <b-card class="mb-4">
      <div class="d-grid gap-5">
        <b-row>
          <b-col md="4" class="text-gray-600">状態</b-col>
          <b-col md="8">{{ subsOrder.stateI18n }}</b-col>
        </b-row>

        <b-row>
          <b-col md="4" class="text-gray-600">小計(税抜)</b-col>
          <b-col md="8">{{ subsOrder.subtotal }}円</b-col>
        </b-row>

        <b-row>
          <b-col md="4" class="text-gray-600">自動注文発生件数 </b-col>
          <b-col md="8">無制限</b-col>
        </b-row>

        <!-- <b-row>
          <b-col md="4" class="text-gray-600">ポイント利用 </b-col>
          <b-col md="8"></b-col>
        </b-row>

        <b-row>
          <b-col md="4" class="text-gray-600">クーポンコード </b-col>
          <b-col md="8"></b-col>
        </b-row> -->

        <b-row>
          <b-col md="4" class="text-gray-600">支払い方法 </b-col>
          <b-col md="8">{{ subsOrder.paymentMethod }}</b-col>
        </b-row>

        <b-row>
          <b-col md="4" class="text-gray-600">配送サイクル </b-col>
          <b-col md="8">{{ subsOrder.paymentScheduleI18n }}</b-col>
        </b-row>

        <b-row>
          <b-col md="4" class="text-gray-600">何ヶ月ごとの何日に配送（日付指定） </b-col>
          <b-col md="8">{{ subsOrder.fullRecreateOrderDetail }}</b-col>
        </b-row>

        <b-row>
          <b-col md="4" class="text-gray-600">お届け時間 </b-col>
          <b-col md="8">{{ subsOrder.scheduledDeliveryTime }}</b-col>
        </b-row>

        <b-row>
          <b-col md="4" class="text-gray-600">次回配送予定日 </b-col>
          <b-col md="8"> {{ subsOrder.scheduledToBeDeliveredAt }}</b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="text-gray-600">作成日</b-col>
          <b-col md="8">
            {{ filters.fullDateTime(subsOrder.createdAt) }}
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div v-if="subsOrder.reactivable">
      <b-card class="my-5 reactive-notice"
        >定期を再開するには次回配送予定日を選択し、再開ボタンを押してください。
      </b-card>
      <b-card>
        <b-row class="mt-3">
          <b-col md="4" class="text-gray-600">
            <form-validator label="次回配送予定日" required />
          </b-col>
          <b-col md="8">
            <b-select
              v-model="reactiveScheduledToBeDeliveredAt"
              :options="reactiveScheduledToBeDeliveredAtOptions"
              class="form-control"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>

    <div class="d-flex justify-content-between mt-8">
      <div>
        <!-- <a v-if="subsOrder.state === 'active'" class="btn btn-primary">編集</a> -->

        <a href="/shop/customer/subs_orders/" class="btn btn-dark mx-5"> 戻る </a>
      </div>
      <div v-if="subsOrder.reactivable">
        <button
          class="btn btn-primary"
          @click="$emit('reactiveSubsOrder', reactiveScheduledToBeDeliveredAt)"
        >
          再開
        </button>
      </div>

      <div v-if="subsOrder.cancelable">
        <button class="btn btn-danger" @click="cancelSuspendSubsOrder">停止</button>
      </div>
    </div>

    <b-modal
      v-model="modalCancelShow"
      hide-header
      @ok="$emit('cancelSubsOrder')"
      ok-title="OK"
      cancel-title="取消"
      ok-variant="danger"
    >
      本当に停止しますか？
    </b-modal>
  </div>
</template>

<script lang="ts" setup>
import filters from "@/composables/filters";
import { computed, ref } from "vue";
import { useBaseInfoStore } from "@/stores/base_info";
import { useSubsOrdersStore } from "@/stores/customer/subsOrders";

import { CustomersSubsOrderType } from "@/types/customers/CustomersSubsOrderType";

interface Props {
  subsOrder: CustomersSubsOrderType;
  messageUpdate: string | null;
}

const modalCancelShow = ref(false);
const reactiveScheduledToBeDeliveredAt = ref("");

const baseInfoStore = useBaseInfoStore();
const subsOrdersStore = useSubsOrdersStore();

const baseInfo = computed(() => baseInfoStore.baseInfo);

const reactiveScheduledToBeDeliveredAtOptions = computed(() => {
  const options = props.subsOrder.reactiveScheduledToBeDeliveredAtOptions || [];

  if (options.length && !props.subsOrder.scheduledToBeDeliveredAt)
    reactiveScheduledToBeDeliveredAt.value = options[0].value;
  else {
    const includeScheduledToBeDeliveredAt = options
      .map((item) => item.value)
      .includes(props.subsOrder.scheduledToBeDeliveredAt);

    reactiveScheduledToBeDeliveredAt.value = includeScheduledToBeDeliveredAt
      ? props.subsOrder.scheduledToBeDeliveredAt
      : options[0].value;
  }

  return options || [];
});

defineEmits(["cancelSubsOrder", "reactiveSubsOrder"]);

function cancelSuspendSubsOrder() {
  if (baseInfo.value?.subsOrderCancelableReason) {
    subsOrdersStore.cancelable = true;

    window.location.href = "/shop/customer/subs_orders/" + props.subsOrder.number + "/cancel";
  } else {
    modalCancelShow.value = true;
  }
}

const props = defineProps<Props>();
</script>

<style scoped>
.update-notice {
  background-color: #dff0d8;
}
.reactive-notice {
  background-color: #d9edf7;
}
</style>
