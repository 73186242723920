export const faqs = `
  query {
    faqs 
    {
      id
      title
      content
      sanitizeContent
    }
  }`;
