export const shippingAddresses = `
  query ($input: PagyInput!) {
    customersShippingAddresses(input: $input) {
      collection {
        id
        name01
        name02
        kana01
        kana02
        addr01
        addr02
        prefectureId
        prefectureName
        tel01
        tel02
        tel03
        zip01
        zip02
      }
      metadata
    }
  }
`;

export const customersShippingAddress = `
  query ($id: ID!) {
    customersShippingAddress(id: $id) {
      id
      name01
      name02
      kana01
      kana02
      addr01
      addr02
      prefectureId
      tel01
      tel02
      tel03
      zip01
      zip02
      lockVersion
    }
  }
`;

export const customersShippingAddressUpdate = `
  mutation ($id: ID!, $input: CustomersShippingAddressInput!) {
    customersShippingAddressUpdate(id: $id, input: $input) {
      shippingAddress {
        id
        name01
        name02
        kana01
        kana02
        addr01
        addr02
        prefectureId
        tel01
        tel02
        tel03
        zip01
        zip02
        lockVersion
      }
      message
    }
  }
`;

export const ordersShippingAddressUpdate = `
  mutation($number: String!, $input: CustomersShippingAddressInput!) {
    customersOrdersShippingAddressUpdate(number: $number, input: $input) {
        shippingAddress {
            id
            name01
            name02
            kana01
            kana02
            addr01
            addr02
            prefectureId
            tel01
            tel02
            tel03
            zip01
            zip02
            lockVersion
        }
        message
    }
  }
`;

export const customersShippingAddressCreate = `
  mutation ($input: CustomersShippingAddressInput!) {
    customersShippingAddressCreate(input: $input) {
      message
    }
  }
`;

export const customersShippingAddressDestroy = `
  mutation($id: ID!) {
    customersShippingAddressDestroy(id: $id) {
        message
    }
  }
`;
