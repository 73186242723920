export const publicsContactsValidate = `
query ($email: String, $input: PublicsContactInput){
  publicsContactsValidate(email: $email, input: $input) {
    email
    contact {
      content
      fullName
      fullTel
      fullKana
      contactTypeId
      contactType {
        name
      }
    }
  }
}
`;

export const publicsContactsCreate = `
mutation ($email: String, $input: PublicsContactInput!) {
  publicsContactsCreate(email: $email, input: $input) {
    newContact {
      contact {
        content
        fullName
        fullTel
        fullKana
        number
        id
        contactType {
          name
        }
      }
      email
    }
    message
  }
}
`;
