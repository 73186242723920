<template>
  <form ref="payjp" class="d-none"></form>
</template>

<script lang="ts" setup>
import _ from "lodash";
import { onDeactivated, onMounted, ref } from "vue";

interface PayjpForm extends HTMLElement {
  submit(): void;
}

interface Props {
  payjpToken: string;
}

const props = defineProps<Props>();

const payjp = ref<PayjpForm | null>(null);

onMounted(() => {
  const attrs = {
    id: "payjp-script",
    src: "https://checkout.pay.jp/",
    class: "payjp-button",
    "data-key": props.payjpToken,
  };

  const scriptEl = document.createElement("script");
  _.forEach(attrs, (v, k) => {
    scriptEl.setAttribute(k, v);
  });

  if (payjp.value) {
    payjp.value.appendChild(scriptEl);
    payjp.value.submit = () => { };
  }
});

onDeactivated(() => {
  const iframe = document.getElementById("payjp-checkout-iframe");
  if (iframe) iframe.parentElement?.remove();
  if (payjp.value) payjp.value.innerHTML = "";
  window.PayjpCheckout = null;
});

const open = () => {
  return new Promise((resolve, reject) => {
    try {
      if (!window.PayjpCheckout) return;

      window.PayjpCheckout.button.click();

      window.PayjpCheckout.createdCallback = (result) => {
        resolve(result.id);
      };

      window.PayjpCheckout.failedCallback = (e) => reject(e);

      window.PayjpCheckout.hide = window.PayjpCheckout.hideIframeWrapper;
      window.PayjpCheckout.hideIframeWrapper = () => {
        if (window.PayjpCheckout) {
          window.PayjpCheckout.hide();
          window.PayjpCheckout.hideIframeWrapper = window.PayjpCheckout.hide;
        }

        resolve(null);
      };
    } catch (e) {
      reject(e);
    }
  });
};

defineExpose({
  open,
});
</script>
