import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as productQueries from "@/graphql/queries/shop/products";
import { ProductType } from "@/types/shop/ProductType";
import { PagyInput } from "@/types/input/PagyInput";

interface State {
  pagy: PagyInput;
  metadata: any;
  products: ProductType[];
}

export const useProductsStore = defineStore({
  id: "products",

  state: (): State => ({
    metadata: {},
    pagy: {
      perPage: 10,
      page: 1,
      q: {},
    },
    products: [],
  }),

  actions: {
    fetchProducts() {
      useGraphql<ProductType[]>(productQueries.publicProducts, {
        input: this.pagy,
      }).then((data: any) => {
        this.products = data.collection;
        this.metadata = data.metadata;
      });
    },
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProductsStore, import.meta.hot));
}
