import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as orderQueries from "@/graphql/queries/customers/orders";
import { CustomersOrderType } from "@/types/customers/CustomersOrderType";
import { CustomersOrderTypePagination } from "@/types/customers/CustomersOrderTypePagination";
import { CustomersTargetConsumptionType } from "@/types/customers/CustomersTargetConsumptionsType";
import { CustomersTargetSubtotalType } from "@/types/customers/CustomersTargetSubtotalType";
import { PagyInput } from "@/types/input/PagyInput";

interface State {
  orders: CustomersOrderType[];
  metadata: any;
  pagy: PagyInput;
  order: CustomersOrderType | null;
  consumptions: CustomersTargetConsumptionType[];
  subtotals: CustomersTargetSubtotalType[];
}

export const useOrdersStore = defineStore({
  id: "orders",

  state: (): State => ({
    orders: [],
    metadata: {},
    pagy: {
      page: 1,
      perPage: 10,
      q: {},
    },
    order: null,
    consumptions: [],
    subtotals: [],
  }),

  actions: {
    fetchOrders() {
      useGraphql<CustomersOrderTypePagination>(orderQueries.orders, { input: this.pagy }).then(
        (data) => {
          this.orders = data.collection;
          this.metadata = data.metadata;
        },
      );
    },

    fetchOrder(number: string) {
      useGraphql<CustomersOrderType>(orderQueries.order, { number }).then((data: any) => {
        this.order = data.order;
      });
    },

    fetchSubtotals(number: string) {
      useGraphql<CustomersTargetSubtotalType>(orderQueries.subtotals, { number }).then((data: any) => {
        this.subtotals = data;
      })
    },

    fetchConsumptions(number: string) {
      useGraphql<CustomersTargetConsumptionType>(orderQueries.customersTargetConsumption, { number }).then((data: any) => {
        this.consumptions = data;
      })
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrdersStore, import.meta.hot));
}
