<template>
  <b-card>
    <div class="d-grid gap-3">
      <FormAddress
        :addressData="formValues.billingAddressData"
        prefix="billingAddressData"
        prefixNameValidator="billing_address_data"
      ></FormAddress>

      <FormValidator
        label="性別"
        clientName="customerData.sexId"
        float
        name="customer_data.sex_id"
        required
      >
        <b-select v-model="formValues.customerData.sexId" :options="sexOptions">
          <template #first>
            <option :value="null">選択してください</option>
          </template>
        </b-select>
      </FormValidator>

      <FormValidator
        label="生年月日"
        clientName="customerData.birthday"
        float
        name="customer_data.birthday"
        required
      >
        <b-input v-model="formValues.customerData.birthday" type="date"></b-input>
      </FormValidator>

      <FormValidator
        label="メールアドレス"
        clientName="customerData.email"
        float
        name="customer_data.email"
        required
      >
        <b-input
          v-model="formValues.customerData.email"
          placeholder="例）○○○@example.com"
          type="email"
        ></b-input>
      </FormValidator>

      <FormValidator
        label="パスワード"
        clientName="customerData.password"
        float
        name="customer_data.password"
        required
      >
        <b-input
          v-model="formValues.customerData.password"
          placeholder="半角英数字8文字以上（大文字小文字をそれぞれ含む）"
          type="password"
        ></b-input>
      </FormValidator>

      <!-- <FormValidator class="mt-5">
        <b-checkbox v-model="formValues.customerData.optin" switch>
          ショップからのお知らせを受け取る
        </b-checkbox>
      </FormValidator> -->

      <b-checkbox v-model="termAccepted" :key="Math.random()">
        <a href="/info/customer_term">利用規約</a>
        に同意して申込みます。未成年者については法定代理人の同意を得ていることを確認します。
      </b-checkbox>
    </div>

    <div class="text-center mt-5">
      <b-button
        size="lg"
        class="btn-register"
        variant="success"
        type="submit"
        :disabled="!termAccepted"
        @click="submitForm"
      >
        登録
      </b-button>
    </div>
  </b-card>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import useGraphql from "@/composables/useGraphql";
import * as authQueries from "@/graphql/queries/customers/auth";
import { SharedGeneralsSelectOptionsType } from "@/types/shared/generals/SharedGeneralsSelectOptionsType";
import * as commonQueries from "@/graphql/queries/common";
import { SignUpInput } from "@/types/input/customers/SignUpInput";
import { useValidationStore } from "@/stores/form/validation";
import {
  addressDataSchemaFragment,
  customerBasicInfoSchemaFragment,
} from "@/composables/useCartValidation";

import FormAddress from "@/components/molecules/pages/cart/FormAddress.vue";

const prefectureOptions = ref([]) as any;
const sexOptions = ref([]) as any;
const termAccepted = ref(false);
const validationStore = useValidationStore();

const { values: formValues, validate } = useForm({
  validationSchema: yup.object({
    billingAddressData: yup.object(addressDataSchemaFragment),
    customerData: yup.object({
      email: yup.string().required().email(),
      password: yup.string().required().min(8),
      ...customerBasicInfoSchemaFragment,
    }),
  }),
  initialValues: new SignUpInput(),
});

const fetchSelectOptions = () => {
  useGraphql<SharedGeneralsSelectOptionsType>(commonQueries.selectOptions, {
    keys: ["sexes", "prefectures"],
  }).then((data) => {
    prefectureOptions.value = data.prefectures;
    sexOptions.value = data.sexes;
  });
};

onMounted(() => {
  fetchSelectOptions();
});

const submitForm = async () => {
  validationStore.submitAttempts++;

  const result = await validate();

  if (!result.valid) return;

  formValues.customerData.emailConfirmation = formValues.customerData.email;
  formValues.customerData.passwordConfirmation = formValues.customerData.password;

  useGraphql<any>(authQueries.signUp, { input: formValues }, { toasted: true }).then((result) => {
    setTimeout(() => {
      window.location.href = "/shop/customers/sign_in";
    }, 600);
  });
};
</script>

<style lang="scss" scoped>
.input-tel {
  .form-group {
    max-width: 33%;
  }
  :deep(.invalid-feedback > div) {
    width: 400px;
    white-space: pre-line;
    @media (max-width: 768px) {
      width: 220px;
    }
  }
}

.btn-register {
  width: unset !important;
  height: unset !important;
  padding: 20px 60px !important;
}
</style>
