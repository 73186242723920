<template>
  <b-card class="w-50 m-auto mt-5 mb-5">
    <h1 class="text-center mb-15 mt-3 pb-5">プライバシーポリシー</h1>
    <p class="mb-10">
      {{ baseInfo?.companyName }} は、
      個人情報保護の重要性に鑑み、「個人情報の保護に関する法律」及び本プライバシーポリシーを遵守し、お客さまのプライバシー保護に努めます。
    </p>
    <PrivacyList></PrivacyList>
  </b-card>
</template>

<script lang="ts" setup>
import PrivacyList from "@/components/organisms/pages/privacy/PrivacyList.vue";

import { computed } from "vue";
import { useBaseInfoStore } from "@/stores/base_info";

const baseInfoStore = useBaseInfoStore();
baseInfoStore.fetchBaseInfo();

const baseInfo = computed(() => baseInfoStore.baseInfo);
</script>
