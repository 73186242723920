<template>
  <Notice>
    <template #icon>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path
          opacity="0.3"
          d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
          fill="currentColor"
        ></path>
        <path
          d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
          fill="currentColor"
        ></path>
      </svg>
    </template>
    お届け先の登録、編集を行うことができます。
    <br />
    ご登録いただいたお届け先は次回ご注文時に使用することができます。
  </Notice>

  <b-card no-body footer-class="d-flex align-items-center justify-content-between py-5">
    <template #header>
      <div class="py-5">
        <div class="card-title">
          <h3>現在登録されているお届け先</h3>
        </div>
        <div class="text-muted fw-normal">
          <b>{{ addressStore.metadata.count }}</b> 件該当しました
        </div>
      </div>
      <div class="card-toolbar text-gray-700">
        <b-button variant="primary" to="/shop/customer/shipping_addresses/new">新規作成</b-button>
      </div>
    </template>

    <div class="table-responsive">
      <table
        class="table table-flush align-middle table-row-bordered table-row-solid gy-4 gx-2 gs-9 mb-0"
      >
        <thead class="fs-7 fw-bold text-uppercase">
          <tr>
            <th>お名前</th>
            <th>フリガナ</th>
            <th>郵便番号</th>
            <th>都道府県</th>
            <th>住所</th>
            <th>電話番号</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody class="fs-6 text-gray-600">
          <tr v-for="address in addressStore.shippingAddresses" :key="address.id">
            <td>{{ address.name01 }} {{ address.name02 }}</td>
            <td>{{ address.kana01 }} {{ address.kana02 }}</td>
            <td>{{ address.zip01 }}{{ address.zip02 }}</td>
            <td>{{ address.prefectureName }}</td>
            <td>{{ address.addr01 }} {{ address.addr02 }}</td>
            <td>{{ address.tel01 }}{{ address.tel02 }}{{ address.tel03 }}</td>
            <td>
              <a
                :href="`/shop/customer/shipping_addresses/${address.id}`"
                class="btn btn-light btn-sm"
              >
                詳細
              </a>
              <a
                v-if="addressStore.metadata.count >= 1"
                href="#"
                class="btn btn-danger btn-sm mx-3"
                @click="showModalConfirm(Number(address.id))"
              >
                削除
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <template #footer>
      <b-select
        style="width: 80px"
        v-model="pagy.perPage"
        :options="[10, 25, 50, 100]"
        size="sm"
        @change="fetchAddresses"
      ></b-select>
      <b-pagination
        v-model="pagy.page"
        :per-page="pagy.perPage"
        :total-rows="addressStore.metadata.count"
        @page-click="fetchAddresses"
      ></b-pagination>
    </template>
  </b-card>

  <!-- Delete Modal -->
  <b-modal
    v-model="modalShow"
    hide-header
    ok-title="OK"
    ok-variant="danger"
    cancel-title="取消"
    @ok="deleteAddress"
  >
    本当に削除しますか？
  </b-modal>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useShippingAddressesStore } from "@/stores/customer/shipping_addresses";
import Notice from "@/components/molecules/common/shared/Notice.vue";

const addressStore = useShippingAddressesStore();
const modalShow = ref(false);
const selectedId = ref(0);

addressStore.fetchShippingAddresses();

const { pagy } = addressStore;

function fetchAddresses() {
  addressStore.fetchShippingAddresses();
}

function deleteAddress() {
  addressStore.deleteShippingAddress(selectedId.value);
}

function showModalConfirm(id: number) {
  selectedId.value = id;
  modalShow.value = true;
}
</script>

<style lang="scss" scoped>
.table-responsive {
  th,
  td {
    white-space: nowrap;
    font-size: 12px;
  }
}
</style>
