<template>
  <b-card>
    <table-show :data="data"> </table-show>
    <div class="d-flex justify-content-between mt-3">
      <a href="/shop/contact/draft" class="btn btn-sm btn-dark">入力内容を修正する</a>
      <b-button @click="onSubmit" variant="primary" class="btn btn-sm btn-primary"
        >この内容で送信する</b-button
      >
    </div>
  </b-card>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useContactStore } from "@/stores/contacts";
import moment from "moment";
import TableShow from "@/components/molecules/pages/contact/TableShow.vue";

const contactStore = useContactStore();

const data = computed(() => contactStore.contact);

const onSubmit = async () => {
  const result: any = await contactStore.publicsContactsCreate(contactStore.contactInput);

  if (!result) return;

  const id = result.newContact.contact.id;

  contactStore.timeStaging = moment().unix();

  window.location.href = `/shop/contact/complete?contact_id=${id}`;
};

onMounted(() => {
  contactStore.confirming = true;

  if (contactStore.timeStaging) window.location.href = "/shop/contact/draft";
});
</script>

<style lang="scss" scoped>
.card {
  td,
  th {
    padding-left: 10px;
  }
}
</style>
