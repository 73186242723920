<template>
  <p class="mb-8">
    {{ baseInfo?.companyName }} は、
    個人情報保護の重要性に鑑み、「個人情報の保護に関する法律」及び本プライバシーポリシーを遵守し、お客さまのプライバシー保護に努めます。
  </p>
  <PrivacyList></PrivacyList>
</template>

<script lang="ts" setup>
import PrivacyList from "@/components/organisms/pages/privacy/PrivacyList.vue";

import { computed } from "vue";
import { useBaseInfoStore } from "@/stores/base_info";

const baseInfoStore = useBaseInfoStore();

const baseInfo = computed(() => baseInfoStore.baseInfo);
</script>
