import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as informationQueries from "@/graphql/queries/shop/information";
import { InformationType } from "@/types/shop/InformationType";
import { PagyInput } from "@/types/input/PagyInput";

interface State {
  metadata: any;
  pagy: PagyInput;
  informationCategories: InformationType[];
}

export const useInformationCategoriesStore = defineStore({
  id: "informationCategory",

  state: (): State => ({
    metadata: {},
    informationCategories: [],
    pagy: {
      page: 1,
      perPage: 10,
      q: {
        information_category_slug_eq: "",
      },
    },
  }),

  actions: {
    fetchInformationCategories(slug: string) {
      this.pagy.q.information_category_slug_eq = slug;
      useGraphql<InformationType[]>(informationQueries.informations, {
        slug: slug,
        input: this.pagy,
      }).then((data: any) => {
        this.informationCategories = data.collection;
        this.metadata = data.metadata;
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInformationCategoriesStore, import.meta.hot));
}
