export const subsOrders = `
query($input: PagyInput!) {
  customersSubsOrders (input: $input){
    collection {
      number
      state
      stateI18n
      subtotal
      createdAt
      fullRecreateOrderDetail
      scheduledToBeDeliveredAt
      orders{
        number
      }
    }
    metadata
  }
}
`;
const subsOrderAttributes = `
  number
  subtotal
  cancelable
  stateI18n
  state
  reactivable
  paymentMethod
  scheduledDeliveryTime
  paymentScheduleI18n
  fullRecreateOrderDetail
  scheduledToBeDeliveredAt
  createdAt
  shippingAddress {
    name01
    name02
    addr01
    addr02
    kana01
    kana02
    prefectureId
    prefectureName
    tel01
    tel02
    tel03
    zip01
    zip02
  }
  orderItems{
    quantity
    price
    product{
      name
    }
    variant{
      salesPrice
      variantOptionValues{
        optionValueName
        productsOptionType{
          optionTypeName
        }
      }
    }
  }
  orders {
    paymentMethod
    number
    orderedAt
  }
  reactiveScheduledToBeDeliveredAtOptions {
    text
    value
  }
`;

export const subsOrder = `
query($number: String!) {
  customersSubsOrder (number: $number){
    ${subsOrderAttributes}
  }
}   
`;

export const subsOrderCancel = `
mutation($number: String!, $reasonIds: [ID!]) {
  customersSubsOrdersCancel(number: $number, reasonIds: $reasonIds) {
    message
    subsOrder {
      ${subsOrderAttributes}
    }
  }
}
`;

export const subsOrderReactive = `
mutation($number: String!, $scheduledToBeDeliveredAt: String!) {
  customersSubsOrdersReactive(number: $number, scheduledToBeDeliveredAt: $scheduledToBeDeliveredAt) {
    message
    subsOrder {
      ${subsOrderAttributes}
    }
  }
}
`;
