export const policiesQuery = `
  query($input: PagyInput!) {
    policies (input: $input)
    {
      id
      title
      content
      sanitizeContent
      policyType
    }
  }`;
