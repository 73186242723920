import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as informationQueries from "@/graphql/queries/shop/information";
import { InformationType } from "@/types/shop/InformationType";
import { PagyInput } from "@/types/input/PagyInput";
interface State {
  pagy: PagyInput;
  metadata: any;
  informations: InformationType[];
  information: InformationType | null;
}

export const useInformationsStore = defineStore({
  id: "informations",

  state: (): State => ({
    metadata: {},
    pagy: {
      perPage: 10,
      page: 1,
      q: {},
    },
    informations: [],
    information: null,
  }),

  actions: {
    fetchInformations() {
      useGraphql<InformationType[]>(informationQueries.informations, {
        input: this.pagy,
      }).then((data: any) => {
        this.informations = data.collection;
        this.metadata = data.metadata;
      });
    },

    fetchInformation(slug: string) {
      const userAgent = navigator.userAgent;
      useGraphql<InformationType>(informationQueries.information, { slug: slug, userAgent }).then(
        (data: any) => {
          this.information = data;
        },
      );
    },

    fetchInformationInShop() {
      useGraphql<InformationType[]>(informationQueries.informations, {
        input: {
          perPage: 3,
          page: 1,
          q: {},
        },
      }).then((data: any) => {
        this.informations = data.collection;
        this.metadata = data.metadata;
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInformationsStore, import.meta.hot));
}
