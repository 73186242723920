import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as lawInfoQueries from "@/graphql/queries/shop/law_info";
import { LawInfoType } from "@/types/shop/LawInfoType";

interface State {
  lawInfo: LawInfoType | null;
}

export const useLawInfoStore = defineStore({
  id: "lawInfo",

  state: (): State => ({
    lawInfo: null,
  }),

  actions: {
    fetchLawInfo() {
      useGraphql<LawInfoType>(lawInfoQueries.lawInfo,{id:1}).then(
        (data) => {
          this.lawInfo = data
        }
      )
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLawInfoStore, import.meta.hot));
}
