<template>
  <b-card>
    <div class="card-body d-flex">
      <div class="col-md-4">
        <img
          v-if="!product.thumbnailUrl"
          src="@/assets/images/shop/defaultImg.png"
          alt="default-image"
        />
        <img v-else :src="product.thumbnailUrl" alt="upload-image" />
      </div>
      <div class="col-8">
        <p style="font-size: 15px">
          <a :href="productUrl" target="_blank"> {{ product.name }}</a>
        </p>
        <div style="font-size: 13px">
          通常価格:
          <span class="text-danger">
            {{ filters.currency(product.masterListPrice) }}
          </span>
          <br />
          販売価格:
          <span class="text-danger">
            {{ filters.currency(product.masterSalesPrice) }}
          </span>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script lang="ts" setup>
import filters from "@/composables/filters";
import { computed } from "vue";

const props = defineProps({
  product: {
    type: Object,
    default: () => {},
  },
});

const productUrl = computed(() => props.product.productUrl || "#");
</script>

<style scoped>
img {
  max-width: 250px;
  max-height: 250px;
}

.text {
  font-size: 15px;
}

.card .card-body {
  padding: 0;
  gap: 15px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}
</style>
