<template>
  <b-card>
    <template #header>
      <div class="pt-4">
        <blockquote>パスワードをリセットする</blockquote>
      </div>
    </template>
    <p>会員情報として登録しているメールアドレスを入力してください。</p>

    <div class="block-content controls">
      <b-form @submit.prevent="submitForm" accept-charset="UTF-8" method="post">
        <div class="form-group">
          <form-validator name="email" clientName="email" required label="メールアドレス" float>
            <input
              v-model="email"
              class="form-control input-sm"
              placeholder="登録メールアドレスを入力"
              type="text"
            />
          </form-validator>
        </div>
        <div class="m-5">
          <div class="form-group center d-flex justify-content-center">
            <input
              class="btn btn-sm btn-success"
              style="width: 320px; height: 40px; font-size: inherit"
              type="submit"
              value="パスワードリセットの案内をメール送信する"
            />
          </div>
        </div>
      </b-form>
      <div class="d-flex justify-content-center">
        <a href="/shop/customers/sign_in">ログインはこちら</a>
      </div>
    </div>
    <p>＜注意点＞</p>
    <p>メールアドレスを入力してもエラーになる場合は、次の可能性があります。</p>
    <ol>
      <li>
        入力されたメールアドレスが一致しない。<br />
        会員情報として登録されている内容を正しく入力する必要があります。<br />
        ご登録時に間違えてご入力されてしまった場合は、その誤った内容のメールアドレスとして登録されます。<br />
      </li>
      <br />
      <li>
        入力されたメールアドレスで会員登録が完了していない。<br />
        会員登録をせずに購入をした場合は、マイページのご利用ができかねます。<br />
        当サイトにて過去に購入されたことがある場合は、その旨を当ショップの運営までお問い合わせください。
      </li>
    </ol>
  </b-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as authQueries from "@/graphql/queries/customers/auth";
import useGraphql from "@/composables/useGraphql";
import { useAuthStore } from "@/stores/auth";
import { CustomersPasswordResetType } from "@/types/customers/CustomersPasswordResetType.js";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useValidationStore } from "@/stores/form/validation";

const authStore = useAuthStore();

if (authStore.isLoggedIn) {
  window.location.href = "/shop/customer/edit";
}

const {
  handleSubmit,
  values: formValues,
  validate,
} = useForm({
  validationSchema: yup.object({
    email: yup.string().required().email(),
  }),
});

const validationStore = useValidationStore();

const { value: email } = useField("email");

const submitForm = async () => {
  validationStore.submitAttempts++;

  const result = await validate();

  if (!result.valid) return;

  useGraphql<CustomersPasswordResetType>(
    authQueries.customersPasswordResetEmailSubmit,
    formValues,
  ).then(() => {
    localStorage.setItem("toast", "true");
    window.location.href = "/shop/customers/sign_in";
  });
};
</script>

<style scoped>
label {
  font-size: inherit;
  font-weight: bold;
}
</style>
