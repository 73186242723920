import { BaseInput } from "../BaseInput";
import { SharedCustomerInput } from "../shared/CustomerInput";
import { CustomersBillingAddressInput } from "./BillingAddressInput";

export interface SignUpInputType {
  billingAddressData: CustomersBillingAddressInput;
  customerData: SharedCustomerInput;
}

export interface CustomerInputType extends SharedCustomerInput {
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
}

export class CustomerInput extends BaseInput implements CustomerInputType {
  constructor(
    public email = "",
    public emailConfirmation = "",
    public password = "",
    public passwordConfirmation = "",
    public birthday = null,
    public optin = true,
    public sexId = null,
  ) {
    super();
  }
}

export class SignUpInput extends BaseInput implements SignUpInputType {
  constructor(
    public customerData = new CustomerInput(),
    public billingAddressData = new CustomersBillingAddressInput(),
  ) {
    super();
  }
}
