<template>
  <div>
    <nav-bar-order-detail :active="`address`" :number="props.number" />
    <form-address type="edit" @onSubmit="update" :backPath="`/shop/customer/orders/${props.number}`" />
  </div>
</template>

<script lang="ts" setup>
import NavBarOrderDetail from "@/components/molecules/common/layout/NavBarOrderDetail.vue";
import FormAddress from "@/components/molecules/pages/customer/ShippingAddress.vue";
import { useShippingAddressesStore } from "@/stores/customer/shipping_addresses";

const addressStore = useShippingAddressesStore();

const props = defineProps<Props>();

interface Props {
  number: string;
}

addressStore.fetchOrderShippingAddressInfo(props.number);

const update = () => {
  addressStore.updateOrderShippingAddress(props.number);
};
</script>
