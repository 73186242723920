import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as faqsQueries from "@/graphql/queries/shop/faqs";
import { FaqType } from "@/types/shop/FaqType";

interface State {
  faqs: FaqType[];
}

export const useFaqsStore = defineStore({
  id: "faqs",

  state: (): State => ({
    faqs: [],
  }),

  actions: {
    fetchFaqs() {
      useGraphql<FaqType[]>(faqsQueries.faqs).then((data) => {
        this.faqs = data;
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFaqsStore, import.meta.hot));
}
