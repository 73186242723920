<template>
  <div>
    <p class="">定期便を停止する理由を選択してください。</p>

    <div class="ms-3">
      <table class="table table-bordered table-striped">
        <tbody>
          <tr v-for="(option, index) in reasonOptions" :key="index">
            <td class="ps-4 py-4">
              <b-form-checkbox v-model="reasonIds" :value="option.value" stacked>
                {{ option.text }}
              </b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-between mt-8">
      <button class="btn btn-dark mx-3 py-3" @click="redirectDetail">戻る</button>
      <button class="btn btn-danger py-3" @click="openConfirmModal">停止</button>
    </div>

    <b-modal
      v-model="modalCancelShow"
      hide-header
      @ok="cancelSubsOrder"
      ok-title="OK"
      cancel-title="取消"
      ok-variant="danger"
    >
      本当に停止しますか？
    </b-modal>
  </div>
</template>

<script lang="ts" setup>
import { useSubsOrdersStore } from "@/stores/customer/subsOrders";
import { useGlobalStore } from "@/stores/global";

import { computed, watch, ref, onBeforeMount } from "vue";

interface Props {
  number: string;
}

const props = defineProps<Props>();

const reasonIds = ref([]);
const modalCancelShow = ref(false);

const subsOrdersStore = useSubsOrdersStore();
const globalStore = useGlobalStore();

const reasonOptions = computed(() => globalStore.selectOptions.subsOrderReasonInMyPage);

onBeforeMount(() => {
  if (!subsOrdersStore.cancelable) redirectDetail();

  subsOrdersStore.cancelable = false;
  subsOrdersStore.fetchSubsOrder(props.number);

  globalStore.fetchSelectOptions(["subs_order_reason_in_my_page"]);
});

function openConfirmModal() {
  modalCancelShow.value = true;
}

function redirectDetail() {
  window.location.href = "/shop/customer/subs_orders/" + props.number;
}

function cancelSubsOrder() {
  subsOrdersStore.cancelSubsOrder(props.number, reasonIds.value);
}

watch(
  () => subsOrdersStore.keepMessage,
  () => {
    if (subsOrdersStore.keepMessage) redirectDetail();
  },
);
</script>
<style lang="scss" scoped>
:deep(.btn-group-vertical) {
  gap: 10px;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px !important;
}
.table tbody tr:first-child td {
  border-top: 1px solid #e1e3e5 !important;
}
.table tbody tr:last-child td {
  border-bottom: 1px solid #e1e3e5 !important;
}
.table {
  --bs-table-bg: white;
  --bs-table-accent-bg: white;
  border-color: #e1e3e5 !important;
}
</style>
