export const guides = `
  query {
    customersGuides {
      id
      title
      content
      sanitizeContent
    }
  }
`;
