import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as guideQueries from "@/graphql/queries/shop/guides";
import { GuideType } from "@/types/shop/GuideType";

interface State {
  guides: GuideType[];
}

export const useGuidesStore = defineStore({
  id: "guides",

  state: (): State => ({
    guides: [],
  }),

  actions: {
    fetchGuides() {
      useGraphql<GuideType[]>(guideQueries.guides).then(
        (data) => {
          this.guides = data;
        },
      );
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGuidesStore, import.meta.hot));
}
