<template>
  <b-card no-body v-for="policy in policiesStore.policies" :key="policy.id" class="mb-9">
    <div class="card-header policy-header">
      <div class="card-title">
        <h3>{{ policy.title }}</h3>
      </div>
    </div>
    <div class="card-body">
      <pre class="policy-content-text"><span v-html="policy.sanitizeContent" /></pre>
    </div>
  </b-card>
</template>
<script lang="ts" setup>
import { usePoliciesStore } from "@/stores/shop/policies";

const policiesStore = usePoliciesStore();

policiesStore.fetchPolicies({
  input: {
    perPage: 100,
    page: 1,
    q: {
      policy_type_eq: "term",
    },
  },
});
</script>
