<template>
  <div class="card-toolbar text-gray-700">
    <strong class="text-danger">{{ ordersStore.metadata.count }}</strong> 件該当しました
  </div>
  <Notice>
    <template #icon>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path
          opacity="0.3"
          d="M18.041 22.041C18.5932 22.041 19.041 21.5932 19.041 21.041C19.041 20.4887 18.5932 20.041 18.041 20.041C17.4887 20.041 17.041 20.4887 17.041 21.041C17.041 21.5932 17.4887 22.041 18.041 22.041Z"
          fill="currentColor"
        />
        <path
          opacity="0.3"
          d="M6.04095 22.041C6.59324 22.041 7.04095 21.5932 7.04095 21.041C7.04095 20.4887 6.59324 20.041 6.04095 20.041C5.48867 20.041 5.04095 20.4887 5.04095 21.041C5.04095 21.5932 5.48867 22.041 6.04095 22.041Z"
          fill="currentColor"
        />
        <path
          opacity="0.3"
          d="M7.04095 16.041L19.1409 15.1409C19.7409 15.1409 20.141 14.7409 20.341 14.1409L21.7409 8.34094C21.9409 7.64094 21.4409 7.04095 20.7409 7.04095H5.44095L7.04095 16.041Z"
          fill="currentColor"
        />
        <path
          d="M19.041 20.041H5.04096C4.74096 20.041 4.34095 19.841 4.14095 19.541C3.94095 19.241 3.94095 18.841 4.14095 18.541L6.04096 14.841L4.14095 4.64095L2.54096 3.84096C2.04096 3.64096 1.84095 3.04097 2.14095 2.54097C2.34095 2.04097 2.94096 1.84095 3.44096 2.14095L5.44096 3.14095C5.74096 3.24095 5.94096 3.54096 5.94096 3.84096L7.94096 14.841C7.94096 15.041 7.94095 15.241 7.84095 15.441L6.54096 18.041H19.041C19.641 18.041 20.041 18.441 20.041 19.041C20.041 19.641 19.641 20.041 19.041 20.041Z"
          fill="currentColor"
        />
      </svg>
    </template>
    ご購入いただいた商品の履歴です。<br />
    ご注文後、発送前の商品に関するお届け先の変更は該当するご注文の詳細をご確認ください。
  </Notice>

  <b-card no-body footer-class="d-flex align-items-center justify-content-between py-5">
    <div class="table-responsive">
      <table
        class="table table-flush align-middle table-row-bordered table-row-solid gy-4 gx-2 gs-9 mb-0"
      >
        <thead class="fs-7 fw-bold text-uppercase">
          <tr>
            <th>購入日</th>
            <th>購入番号</th>
            <th>購入種別</th>
            <th>支払い方法</th>
            <th>対応状況</th>
            <th>決済状況</th>
            <th>合計</th>
            <th>支払い合計</th>
            <th>詳細</th>
          </tr>
        </thead>
        <tbody class="fs-6 text-gray-600">
          <tr v-for="order in ordersStore.orders" :key="order.id">
            <td>{{ filters.fullDateTime(order.orderedAt) }}</td>
            <td>{{ order.number }}</td>
            <td>{{ order.orderTypeI18n }}</td>
            <td>{{ order.paymentMethod }}</td>
            <td>{{ order.humanOrderState }}</td>
            <td>{{ order.paymentState }}</td>
            <td>{{ order.total }}円</td>
            <td>{{ order.paymentTotal }}円</td>
            <td>
              <a :href="`/shop/customer/orders/${order.number}`" class="btn btn-light btn-sm">
                詳細
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-6 mt-2 ml-4 mb-4" v-if="ordersStore.metadata.pages == 1">
        <strong class="ml-4">{{ ordersStore.metadata.count }}</strong>
        件表示中です
      </div>
      <div class="col-md-6 mt-2 ml-4 mb-4" v-else>
        <strong class="ml-4">{{ ordersStore.metadata.count }}</strong>
        件中
        <strong class="ml-4"
          >{{ ordersStore.metadata.from }} - {{ ordersStore.metadata.to }}</strong
        >
        件を表示しています
      </div>
    </div>

    <template #footer>
      <b-pagination
        v-model="pagy.page"
        :per-page="pagy.perPage"
        :total-rows="ordersStore.metadata.count"
        first-text="最初へ"
        last-text="最後へ"
        @page-click="fetchOrders"
      ></b-pagination>
    </template>
  </b-card>
</template>

<script lang="ts" setup>
import { useOrdersStore } from "@/stores/customer/orders";
import filters from "@/composables/filters"
import Notice from "@/components/molecules/common/shared/Notice.vue";

const ordersStore = useOrdersStore();

ordersStore.fetchOrders();

const { pagy } = ordersStore;

function fetchOrders() {
  ordersStore.fetchOrders();
}
</script>

<style lang="scss" scoped>
.table-responsive {
  th,
  td {
    white-space: nowrap;
    font-size: 12px;
  }
}
</style>
