<template>
  <b-card
    no-body
    v-for="faq in faqsStore.faqs"
    :key="faq.id"
    class="mb-9"
  >
    <div class="card-header policy-header">
      <div class="card-title">
        <h3>{{ faq.title }}</h3>
      </div>
    </div>
    <div class="card-body">
      <pre class="policy-content-text">
        <span v-html="faq.sanitizeContent" />
      </pre>
    </div>
  </b-card>
</template>
<script lang="ts" setup>
import { useFaqsStore } from "@/stores/shop/faqs";

const faqsStore = useFaqsStore();

faqsStore.fetchFaqs();
</script>
