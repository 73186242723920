import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as publicContactQueries from "@/graphql/queries/publics/contacts";
import * as commonQueries from "@/graphql/queries/common";
import { SharedGeneralsSelectOptionType } from "@/types/shared/generals/SharedGeneralsSelectOptionType";
import { ContactType } from "@/types/contactTypes";
import { ContactInput } from "@/types/input/contactInput";

interface State {
  contact: ContactType;
  contactInput: ContactInput;
  confirming:Boolean,
  contactTypes: SharedGeneralsSelectOptionType[];
  timeStaging: number; //to check whether to reset contact data
}

export const useContactStore = defineStore({
  id: "contacts",

  state: (): State => ({
    contact: {},
    contactTypes: [],
    timeStaging: 0,
    confirming:false,
    contactInput: new ContactInput(),
  }),

  actions: {
    async contactsValidate(input: any) {
      const data = await useGraphql(publicContactQueries.publicsContactsValidate, input);
      if (data) {
        this.contact = data.contact;
        this.contact.email = data.email;
      }
      return data;
    },

    async publicsContactsCreate(input: any) {
      const data = await useGraphql(publicContactQueries.publicsContactsCreate, input, {
        toasted: true,
      });

      if (data) {
        this.contact = data.newContact.contact;
        this.contact.email = data.newContact.email;
      }
      return data;
    },

    async selectOptions() {
      const data: any = await useGraphql(commonQueries.selectOptions, {
        keys: ["contact_type_options"],
      });

      this.contactTypes = data.contactTypeOptions;
    },
  },

  persist: {
    key: "united-cart-contacts",
    storage: window.localStorage,
    paths: ["contact", "timeStaging", "contactInput","confirming"],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContactStore, import.meta.hot));
}
