import useGraphql from "@/composables/useGraphql";
import { policiesQuery } from "@/graphql/queries/shop/policies";

import { defineStore, acceptHMRUpdate } from "pinia";
import { PolicyType } from "@/types/shop/PolicyType";

type State = {
  policies: PolicyType[];
};

export const usePoliciesStore = defineStore({
  id: "policy",

  state: (): State => ({
    policies: [],
  }),

  actions: {
    async fetchPolicies(payload: any) {
      const data = await useGraphql<PolicyType[]>(policiesQuery, payload);
      this.policies = data;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePoliciesStore, import.meta.hot));
}
