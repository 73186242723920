import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as subsOrderQueries from "@/graphql/queries/customers/subs_orders";
import { CustomersSubsOrderType } from "@/types/customers/CustomersSubsOrderType";
import { CustomersSubsOrderTypePagination } from "@/types/customers/CustomersSubsOrderTypePagination";
import { PagyInput } from "@/types/input/PagyInput";

interface State {
  subsOrders: CustomersSubsOrderType[];
  metadata: any;
  pagy: PagyInput;
  subsOrder: CustomersSubsOrderType | null;
  messageUpdate: string | null;
  cancelable: boolean;
  keepMessage: boolean;
}

export const useSubsOrdersStore = defineStore({
  id: "subs_orders",

  state: (): State => ({
    subsOrders: [],
    metadata: {},
    pagy: {
      page: 1,
      perPage: 10,
      q: {},
    },
    subsOrder: null,
    messageUpdate: null,
    keepMessage: false,
    cancelable: false,
  }),

  actions: {
    fetchSubsOrders() {
      useGraphql<CustomersSubsOrderTypePagination>(subsOrderQueries.subsOrders, {
        input: this.pagy,
      }).then((data) => {
        this.subsOrders = data.collection;
        this.metadata = data.metadata;
      });
    },

    fetchSubsOrder(number: string) {
      useGraphql<CustomersSubsOrderType>(subsOrderQueries.subsOrder, { number }).then((data) => {
        this.subsOrder = data;
      });
    },

    cancelSubsOrder(number: string, reasonIds?: string[]) {
      useGraphql<any>(subsOrderQueries.subsOrderCancel, { number, reasonIds }).then((data) => {
        this.subsOrder = data.subsOrder;
        this.messageUpdate = data.message;
        if (reasonIds) this.keepMessage = true;
      });
    },

    reactiveSubsOrder(number: string, scheduledToBeDeliveredAt: string) {
      useGraphql<any>(subsOrderQueries.subsOrderReactive, {
        number,
        scheduledToBeDeliveredAt,
      }).then((data) => {
        this.subsOrder = data.subsOrder;
        this.messageUpdate = data.message;
      });
    },
  },

  persist: {
    storage: window.localStorage,
    paths: ["cancelable", "messageUpdate", "keepMessage"],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSubsOrdersStore, import.meta.hot));
}
