<template>
  <div v-if="lawInfo">
    <table class="table mb-0">
      <thead>
        <th width="50%"></th>
        <th width="50%"></th>
      </thead>

      <tbody>
        <tr>
          <td>販売業者名</td>
          <td>{{ lawInfo.companyName }}</td>
        </tr>
        <tr>
          <td>販売責任者</td>
          <td>{{ lawInfo.manager }}</td>
        </tr>
        <tr>
          <td>所在地</td>
          <td>{{ lawInfo.fullAddress }}</td>
        </tr>
        <tr>
          <td>電話番号</td>
          <td>{{ lawInfo.tel }}</td>
        </tr>
        <tr>
          <td>FAX 番号</td>
          <td>{{ lawInfo.fax }}</td>
        </tr>
        <tr>
          <td>メールアドレス</td>
          <td>{{ lawInfo.email }}</td>
        </tr>
        <tr>
          <td>ショップ名</td>
          <td>{{ lawInfo.shopName }}</td>
        </tr>
        <tr>
          <td>ホームページURL</td>
          <td>{{ lawInfo.url }}</td>
        </tr>
        <tr>
          <td>販売価格</td>
          <td>{{ lawInfo.price }}</td>
        </tr>
        <tr>
          <td>販売数量</td>
          <td>{{ lawInfo.quantity }}</td>
        </tr>
        <tr v-for="(lawText, index) in lawInfo.lawTexts" :key="index">
          <td>
            <pre><span v-html="lawText.sanitizeTitle" class="white-space-pre-line"/></pre>
          </td>
          <td>
            <pre class="mb-0 white-space-pre-line"><span v-html="lawText.sanitizeContent" /></pre>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useLawInfoStore } from "@/stores/shop/law_info";

const lawInfoStore = useLawInfoStore();

lawInfoStore.fetchLawInfo();

const lawInfo = computed(() => lawInfoStore.lawInfo);
</script>

<style lang="scss" scoped>
.table {
  tr {
    td {
      padding: 8px !important;
      border: 1px solid #ddd;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid #ddd !important;
    }
  }
}
</style>
