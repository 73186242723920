<template>
  <b-card class="mb-8">
    <template #header>
      <b-card-title>Softbank</b-card-title>
      <b-button variant="primary" size="sm" @click="sbModalShow = true"
        >{{ softbankCards.length ? "アップデート" : "新規作成" }}
      </b-button>
    </template>

    <div v-if="softbankCards.length" class="row row-cols-1 row-cols-md-2 g-5">
      <div class="col" v-for="(card, index) in softbankCards" :key="index">
        <CreditCard :card="card" deleteable @delete="deleteSoftbankCards"></CreditCard>
      </div>
    </div>
    <div v-else>現在登録されているカードはありません</div>
  </b-card>

  <b-modal v-model="sbModalShow" title="お支払い情報" body-class="p-0" hide-footer hide-header>
    <CardForm :card="card" @submitForm="addSbCard"></CardForm>
  </b-modal>
</template>

<script lang="ts" setup>
import { computed, inject, ref, reactive } from "vue";
import { useCardsStore } from "@/stores/customer/cards";
import { CreditCardInput } from "@/types/input/customers/CardInput";
import { Toaster } from "@meforma/vue-toaster";
import { linkCreditCard } from "@/composables/payment_cards/soft_bank_card";

import CreditCard from "@/components/molecules/common/shared/CreditCard.vue";
import CardForm from "@/components/molecules/pages/customer/cards/CardForm.vue";

interface Props {
  merchantId: string;
  serviceId: string;
}

const props = defineProps<Props>();

const card = reactive(new CreditCardInput());

const toast = inject<Toaster>("toast")!;

const sbModalShow = ref(false);

const cardsStore = useCardsStore();

cardsStore.fetchSoftbankCards();

const softbankCards = computed(() => cardsStore.softbankCards);

const addSbCard = () => {
  const setting = {
    merchantId: props.merchantId,
    serviceId: props.serviceId,
  };

  linkCreditCard(card, setting, toast, cardsStore.modifySoftbankCards);

  sbModalShow.value = false;
};

const deleteSoftbankCards = cardsStore.deleteSoftbankCards;
</script>
