<template>
  <div v-if="orderDetail">
    <nav-bar-order-detail :active="`item`" :number="props.number" />
    <order-items :order-items="orderDetail.orderItems" />
    <order-details :order="orderDetail" :subtotals="subtotals" :consumptions="consumptions" />
  </div>
</template>

<script lang="ts" setup>
import { useOrdersStore } from "@/stores/customer/orders";
import { computed } from "vue";
import NavBarOrderDetail from "@/components/molecules/common/layout/NavBarOrderDetail.vue";
import OrderItems from "@/components/organisms/pages/customer/orders/OrderItems.vue";
import OrderDetails from "@/components/organisms/pages/customer/orders/OrderDetails.vue";

interface Props {
  number: string;
}

const props = defineProps<Props>();

const ordersStore = useOrdersStore();

ordersStore.fetchOrder(props.number);

ordersStore.fetchConsumptions(props.number);

ordersStore.fetchSubtotals(props.number);

const orderDetail = computed(() => ordersStore.order);

const subtotals = computed(() => ordersStore.subtotals);

const consumptions = computed(() => ordersStore.consumptions);
</script>

<style lang="scss">
.table-responsive {
  th,
  td {
    white-space: nowrap;
    font-size: 12px;
  }
}
</style>
