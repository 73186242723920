<template>
  <div class="aside card">
    <div class="aside-menu pt-5 flex-column-fluid px-4">
      <div class="hover-scroll-overlay-y my-5 pe-4 me-n4">
        <div
          class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6"
        >
          <div
            class="menu-item"
            v-if="authStore.token"
          >
            <a
              class="menu-link"
              href="/shop/customer"
              :class="{ active: active == 'customer' }"
            >
              <span class="menu-icon">
                <span class="svg-icon svg-icon-2">
                  <MenuIcon />
                </span>
              </span>
              <span class="menu-title">マイページ</span>
              <span class="menu-arrow"></span>
            </a>
          </div>

          <div v-if="baseInfo?.enableShopProductList">
            <div class="menu-item">
              <a
                class="menu-link"
                href="/shop/products"
                :class="{ active: active == 'products' }"
              >
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <ProductIcon />
                  </span>
                </span>
                <span class="menu-title">商品一覧</span>
                <span class="menu-arrow"></span>
              </a>
            </div>
          </div>

          <div v-if="authStore.token">
            <div class="menu-item pt-5">
              <div class="menu-content">
                <span class="menu-heading fw-bold text-uppercase fs-7">
                  Dashboard
                </span>
              </div>
            </div>

            <div class="menu-item">
              <a
                class="menu-link"
                href="/shop/customer/orders"
                :class="{ active: active == 'orders' }"
              >
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <OrderIcon />
                  </span>
                </span>
                <span class="menu-title">購入履歴</span>
                <span class="menu-arrow"></span>
              </a>
            </div>

            <div class="menu-item">
              <a
                class="menu-link"
                href="/shop/customer/subs_orders"
                :class="{ active: active == 'subs_orders' }"
              >
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <SubOrderIcon />
                  </span>
                </span>
                <span class="menu-title">定期管理</span>
                <span class="menu-arrow"></span>
              </a>
            </div>

            <!-- <div class="menu-item">
            <a
              class="menu-link"
              href="/shop/customer/favorites"
              :class="{ active: active == 'favorites' }"
            >
              <span class="menu-icon">
                <span class="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </span>
              <span class="menu-title">お気に入り一覧</span>
              <span class="menu-arrow"></span>
            </a>
          </div> -->

            <div class="menu-item">
              <a
                class="menu-link"
                href="/shop/customer/edit"
                :class="{ active: active == 'edit' }"
              >
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <UserIcon />
                  </span>
                </span>
                <span class="menu-title">会員情報変更</span>
                <span class="menu-arrow"></span>
              </a>
            </div>

            <div class="menu-item">
              <a
                class="menu-link"
                href="/shop/customer/shipping_addresses"
                :class="{ active: active == 'shipping_addresses' }"
              >
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <AddressIcon />
                  </span>
                </span>
                <span class="menu-title">お届け先登録</span>
                <span class="menu-arrow"></span>
              </a>
            </div>

            <div class="menu-item">
              <a
                class="menu-link"
                href="/shop/customer/cards"
                :class="{ active: active == 'cards' }"
              >
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <CardIcon />
                  </span>
                </span>
                <span class="menu-title">カード情報変更</span>
                <span class="menu-arrow"></span>
              </a>
            </div>

            <div class="menu-item">
              <a
                class="menu-link"
                href="/shop/customer/password"
                :class="{ active: active == 'password' }"
              >
                <span class="menu-icon">
                  <span class="svg-icon svg-icon-2">
                    <PasswordIcon />
                  </span>
                </span>
                <span class="menu-title">パスワード</span>

                <span class="menu-arrow"></span>
              </a>
            </div>
          </div>

          <div class="menu-item pt-5">
            <div class="menu-content">
              <span class="menu-heading fw-bold text-uppercase fs-7">
                Pages
              </span>
            </div>
          </div>

          <div class="menu-item">
            <a
              class="menu-link"
              href="/shop/base_info"
              :class="{ active: active == 'base_info' }"
            >
              <span class="menu-title">当サイトについて</span>
              <span class="menu-arrow"></span>
            </a>
          </div>

          <div class="menu-item">
            <a
              class="menu-link"
              href="/shop/law_info"
              :class="{ active: active == 'law_info' }"
            >
              <span class="menu-title">特定商取引法に基づく表記</span>
              <span class="menu-arrow"></span>
            </a>
          </div>

          <div class="menu-item">
            <a
              class="menu-link"
              href="/shop/privacy"
              :class="{ active: active == 'privacy' }"
            >
              <span class="menu-title">プライバシーポリシー</span>
              <span class="menu-arrow"></span>
            </a>
          </div>

          <div class="menu-item">
            <a
              class="menu-link"
              href="/shop/guide"
              :class="{ active: active == 'guide' }"
            >
              <span class="menu-title">ご利用ガイド</span>
              <span class="menu-arrow"></span>
            </a>
          </div>

          <div class="menu-item">
            <a
              class="menu-link"
              href="/shop/customer_term"
              :class="{ active: active == 'customer_term' }"
            >
              <span class="menu-title">利用規約</span>
              <span class="menu-arrow"></span>
            </a>
          </div>

          <div class="menu-item">
            <a
              class="menu-link"
              href="/shop/faq"
              :class="{ active: active == 'faq' }"
            >
              <span class="menu-title">よくあるご質問</span>
              <span class="menu-arrow"></span>
            </a>
          </div>

          <div class="menu-item">
            <a
              class="menu-link"
              href="/shop/contact/draft"
              :class="{ active: active == 'contact' }"
            >
              <span class="menu-title">お問い合わせ</span>
              <span class="menu-arrow"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import MenuIcon from "@/components/molecules/common/icons/svg/MenuIcon.vue";
import ProductIcon from "@/components/molecules/common/icons/svg/ProductIcon.vue";
import OrderIcon from "@/components/molecules/common/icons/svg/OrderIcon.vue";
import SubOrderIcon from "@/components/molecules/common/icons/svg/SubOrderIcon.vue";
import UserIcon from "@/components/molecules/common/icons/svg/UserIcon.vue";
import AddressIcon from "@/components/molecules/common/icons/svg/AddressIcon.vue";
import CardIcon from "@/components/molecules/common/icons/svg/CardIcon.vue";
import PasswordIcon from "@/components/molecules/common/icons/svg/PasswordIcon.vue";

interface Props {
  active?: string;
}

defineProps<Props>();

import { useAuthStore } from "@/stores/auth";
import { useBaseInfoStore } from "@/stores/base_info";

const authStore = useAuthStore();

const baseInfoStore = useBaseInfoStore();

const baseInfo = computed(() => baseInfoStore.baseInfo);
</script>

<style lang="scss" scoped>
.aside {
  flex: 0 0 265px;
}

.menu .menu-item {
  padding: 0.15rem 0;

  .menu-link {
    .menu-title {
      transition: color 0.2s ease;
      color: var(--kt-gray-600);
    }

    .menu-arrow::after {
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      background-color: var(--kt-gray-400);
      -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23A1A5B7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
      mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23A1A5B7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
    }

    &.active {
      background-color: var(--kt-aside-menu-link-bg-color-active);
    }

    &:hover,
    &.active {
      .menu-title {
        color: var(--kt-gray-900);
      }

      .menu-arrow::after {
        background-color: var(--kt-gray-900);
      }

      .menu-icon .svg-icon {
        color: var(--kt-gray-900);
      }
    }
  }
}

.menu-heading {
  color: var(--kt-menu-heading-color);
}
</style>
