<template>
  <div>
    <FormAddress type="edit" @onSubmit="update" :backPath="backPath"></FormAddress>
  </div>
</template>

<script lang="ts" setup>
import FormAddress from "@/components/molecules/pages/customer/ShippingAddress.vue";
import { useShippingAddressesStore } from "@/stores/customer/shipping_addresses";

const addressStore = useShippingAddressesStore();

const props = defineProps<Props>();

  const backPath = "/shop/customer/shipping_addresses"

interface Props {
  id: number | string;
}

addressStore.fetchShippingAddressInfo(props.id);

const update = () => {
  addressStore.updateShippingAddress(props.id);
};
</script>
