import { ContactInputType } from "../contactTypes";

export class ContactInput {
  public email: string = "";
  public input = new ContactInfoInput();
}

class ContactInfoInput implements ContactInputType {
  public content = "";
  public contactTypeId: number | null = null;
  public name01 = "";
  public name02 = "";

  public kana01 = "";
  public kana02 = "";

  public tel01 = "";
  public tel02 = "";
  public tel03 = "";
}
