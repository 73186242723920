<template>
  <b-card v-if="information">
    <template #header>
      <div class="pt-4">
        <blockquote class="infomation-text">
          <a href="/shop/information" class="infomation-after">ニュース一覧 </a>
          <a
            class="mx-2 infomation-after"
            :href="`/shop/information_categories/${information.informationCategory.slug}`"
          >
            {{ information.informationCategory.name }}
          </a>
          <a href="#">
            {{ information.title }}
          </a>
        </blockquote>
        <h2>{{ information.title }}</h2>
        <p class="fw-normal">
          {{ filters.jpDateTimeWithText(information.publishedAt) }} |
          <a :href="`/shop/information_categories/${information.informationCategory.slug}`">
            {{ information.informationCategory.name }}
          </a>
        </p>
      </div>
    </template>
    <div class="mb-6">
      <img :src="information.thumbnailUrl" alt="" />
    </div>
    <div v-html="information.contentDevice"></div>
    <hr />
    <div class="d-flex justify-content-between">
      <a :href="`/shop/information/${prev_information?.slug}`">
        <span v-if="prev_information">{{ prev_information.title }} </span>
      </a>
      <a :href="`/shop/information/${next_information?.slug}`">
        <span v-if="next_information">{{ next_information.title }} </span>
      </a>
    </div>
  </b-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useInformationsStore } from "@/stores/shop/informations";
import filters from "@/composables/filters"

const props = defineProps(["slug"]);

const informationsStore = useInformationsStore();

const information = computed(() => informationsStore.information);

const next_information = computed(() => informationsStore.information?.nextInformation);

const prev_information = computed(() => informationsStore.information?.prevInformation);

informationsStore.fetchInformation(props.slug);
</script>
<style lang="scss" scoped>
blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  font-size: 14px;
  padding: 10.5px 21px;
  margin: 0 0 21px;
  font-size: 18.75px;
  border-left: 5px solid #e6e6e6;
}

.infomation-text {
  font-size: 14px;
}
</style>
