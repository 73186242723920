import { AddressInputInterface } from "../shared/AddressInputInterface";

export interface CustomersShippingAddressType extends AddressInputInterface {
  prefectureName?: string;
  fullAddress?: string;
}

export const initCustomersShippingAddressType: CustomersShippingAddressType = {
  id: "",
  name01: "",
  name02: "",
  kana01: "",
  kana02: "",
  zip01: "",
  zip02: "",
  prefectureId: null,
  addr01: "",
  addr02: "",
  tel01: "",
  tel02: "",
  tel03: "",
  lockVersion: 0,
};

export interface CustomersShippingAddressUpdateResponseType {
  shippingAddress: CustomersShippingAddressType;
}
