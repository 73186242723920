<template>
  <div v-if="baseInfo">
    <table class="table mb-0">
      <thead>
        <th width="30%"></th>
        <th width="70%"></th>
      </thead>

      <tbody>
        <tr>
          <td>ショップ名</td>
          <td>{{ baseInfo.shopName }}</td>
        </tr>
        <tr>
          <td>会社名(屋号)</td>
          <td>{{ baseInfo.companyName }}</td>
        </tr>
        <tr>
          <td>所在地</td>
          <td>
            〒{{ baseInfo.zip01 }} - {{ baseInfo.zip02 }} {{ baseInfo.pref }}
            {{ baseInfo.addr01 }}
            {{ baseInfo.addr02 }}
          </td>
        </tr>
        <tr>
          <td>電話番号</td>
          <td>{{ baseInfo.tel }}</td>
        </tr>
        <tr v-if="baseInfo.fax">
          <td>FAX番号</td>
          <td>{{ baseInfo.fax }}</td>
        </tr>
        <tr>
          <td>メールアドレス</td>
          <td>{{ baseInfo.contactEmail }}</td>
        </tr>
        <tr>
          <td>営業時間</td>
          <td>{{ baseInfo.businessHour }}</td>
        </tr>
        <tr>
          <td>取扱商品</td>
          <td>{{ baseInfo.goodTraded }}</td>
        </tr>
        <tr v-if="baseInfo.message">
          <td>メッセージ</td>
          <td>{{ baseInfo.message }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useBaseInfoStore } from "@/stores/base_info";

const baseInfoStore = useBaseInfoStore();

baseInfoStore.fetchBaseInfo();

const baseInfo = computed(() => baseInfoStore.baseInfo);
</script>

<style lang="scss" scoped>
.table {
  tr {
    td {
      padding: 8px !important;
      border: 1px solid #ddd;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid #ddd !important;
    }
  }
}
</style>
