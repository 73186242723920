<template>
  <div class="header">
    <div class="container-xl d-flex flex-stack">
      <div class="d-flex align-items-center me-5">
        <div
          @click="isActive = !isActive"
          class="d-lg-none btn btn-icon btn-active-color-white w-30px h-30px ms-n2 me-3"
        >
          <span class="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </div>
        <div class="side-bar" :class="{ active: isActive }">
          <div class="backdrop" @click="isActive = !isActive"></div>
          <CustomerSidemenu class="nav_mobile"></CustomerSidemenu>
        </div>

        <div v-if="baseInfo">
          <a href="/shop">
            <img :src="baseInfo.logoUrl" alt="Logo" class="normal-logo h-25px h-lg-50px" />
            <img :src="baseInfo.mobileLogoUrl" alt="Logo" class="mobile_logo h-25px h-lg-50px" />
          </a>
        </div>
      </div>

      <UserMenu></UserMenu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import UserMenu from "@/components/molecules/common/layout/UserMenu.vue";
import { useBaseInfoStore } from "@/stores/base_info";
import CustomerSidemenu from "@/components/organisms/layout/CustomerSidemenu.vue";

const baseInfoStore = useBaseInfoStore();

const baseInfo = computed(() => baseInfoStore.baseInfo);

const isActive = ref(false);
</script>

<style lang="scss" scoped>
.header {
  background-color: #1e1e2d;
  width: 100%;
  position: fixed;
}
.active {
  .nav_mobile {
    transform: translateX(0%);
    opacity: 1;
  }
  .backdrop {
    transform: translateX(0%);
  }
}
.backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  animation: navMobile linear 0.3s;
  transform: translateX(-100%);
  display: block !important;
}
.nav_mobile {
  position: fixed;
  top: -10px;
  left: 0px;
  bottom: -10px;
  width: 250px;
  max-width: 100%;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform linear 0.3s, opacity linear 0.3s;
  overflow: scroll;
  display: block !important;
}
@media only screen and (min-width: 992px) {
  .normal-logo {
    display: block;
  }
  .mobile_logo {
    display: none;
  }
  .side-bar {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .normal-logo {
    display: none;
  }
  .mobile_logo {
    display: block;
  }
  .side-bar {
    display: block !important;
  }
}
@keyframes navMobile {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
