export const lawInfo = `
  query {
    lawInfo {
      id
      companyName
      manager
      fullAddress
      tel
      fax
      email
      shopName
      url
      price
      quantity
      lawTexts {
          title
          content
          sanitizeTitle
          sanitizeContent
    }
  }
}`;
