<template>
  <b-card class="mb-8">
    <template #header>
      <b-card-title>Gmopg</b-card-title>
      <b-button variant="primary" size="sm" @click="gmoPgModalShow = true">新規作成</b-button>
    </template>

    <div v-if="gmoPgCards.length" class="row row-cols-1 row-cols-md-2 g-5">
      <div class="col" v-for="card in gmoPgCards" :key="card.cardSeq">
        <CreditCard :card="card" card-index-field="cardSeq" @delete="deletedGmoPgCard" deleteable ></CreditCard>
      </div>
    </div>
    <div v-else>現在登録されているカードはありません</div>
  </b-card>

  <b-modal v-model="gmoPgModalShow" title="お支払い情報" body-class="p-0" hide-footer hide-header>
    <CardForm :card="card" @submitForm="addGmoPgCard"></CardForm>
  </b-modal>
</template>

<script lang="ts" setup>
import { computed, inject, ref, reactive } from "vue";
import { Toaster } from "@meforma/vue-toaster";
import { CreditCardInput } from "@/types/input/customers/CardInput"
import { useCardsStore } from "@/stores/customer/cards";
import { linkCreditCard } from "@/composables/payment_cards/gmo_pg_card";

import CardForm from "@/components/molecules/pages/customer/cards/CardForm.vue"
import CreditCard from "@/components/molecules/common/shared/CreditCard.vue";


interface Props {
  gmoShopId: string;
}

const props = defineProps<Props>();
const card = reactive(new CreditCardInput)

const gmoPgModalShow = ref(false);

const cardsStore = useCardsStore();

const toast = inject<Toaster>("toast")!;

cardsStore.fetchGmoPgCards();

const gmoPgCards = computed(() => cardsStore.gmoPgCards);

const addGmoPgCard = () => {
  linkCreditCard(card, props.gmoShopId, toast, cardsStore.createGmoPgCards);

  gmoPgModalShow.value = false;
};

const deletedGmoPgCard = (cardSeq: string | null) => {
  if (cardSeq) {
    cardsStore.deleteGmoPgCards(cardSeq);
  }
};
</script>
