<template>
  <b-card class="mb-8">
    <template #header>
      <b-card-title>Payjp</b-card-title>
      <b-button variant="primary" size="sm" @click="addCard">新規作成</b-button>
    </template>

    <div v-if="payjpCards.length" class="row row-cols-1 row-cols-md-2 g-5">
      <div class="col" v-for="card in payjpCards" :key="card.id">
        <CreditCard :card="card" card-index-field="id" @delete="deleteCard" deleteable></CreditCard>
      </div>
    </div>

    <div v-else>現在登録されているカードはありません</div>
  </b-card>

  <PaymentPayjp ref="payjp" :payjp-token="payjpToken"></PaymentPayjp>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useCardsStore } from "@/stores/customer/cards";
import { PayjpFormComponent } from "@/types/modules/Payjp";

import CreditCard from "@/components/molecules/common/shared/CreditCard.vue";
import PaymentPayjp from "@/components/shared/PaymentPayjp.vue";

interface Props {
  payjpToken: string;
}

defineProps<Props>();

const cardsStore = useCardsStore();

cardsStore.fetchPayjpCards();

const payjpCards = computed(() => cardsStore.payjpCards);

const payjp = ref<PayjpFormComponent | null>(null);

const addCard = async () => {
  if (payjp.value) {
    const token = await payjp.value.open();
    if (token) {
      cardsStore.createPayjpCard(token);
    }
  }
};

const deleteCard = (cardId: string | null) => {
  if (cardId) {
    cardsStore.payjpCardDelete(cardId);
  }
};
</script>
