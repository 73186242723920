<template>
  <div class="table-responsive mb-0">
    <table class="table table-bordered table-striped">
      <tbody>
        <tr v-for="(header, index) in headers" :key="index">
          <td class="fw-bold contact-title" >
            {{ header }}
          </td>
          <td class="contact-content" colspan="3">
            <p> {{ get(data, rowMappers[index]) }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { get } from "lodash";

interface Props {
  data: any;
}

defineProps<Props>();
const headers = [
  "お問い合わせ種別",
  "メールアドレス",
  "お名前",
  "お名前（カナ）",
  "電話番号",
  "お問い合わせ内容",
];
const rowMappers = ["contactType.name", "email", "fullName", "fullKana", "fullTel", "content"];
</script>

<style lang="scss" scoped>
.card {

  td,
  th {
    padding-left: 10px;
  }
}

div {
  table {
    table-layout: fixed;

    .contact-title {
      min-width: 300px !important;
      padding-right: 5px;
      white-space: normal !important;
    }

    .contact-content {
      padding-right: 5px !important;

      p {
        white-space: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
    }
  }
}
</style>
