<template>
  <b-card footer-class="d-flex justify-content-end py-6 px-9">
    <template #header>
      <div class="card-title m-0">
        <h3 class="fw-bold m-0">お届け先住所</h3>
      </div>
    </template>
    <p>保存する場合は、一番下の「保存」ボタンをクリックしてください。</p>

    <notice> 必須項目 「 ※ 」印は入力必須です。 </notice>

    <FormAddress :addressData="formValues" prefix="" prefixNameValidator=""></FormAddress>

    <template #footer>
      <b-button variant="primary" @click="onSubmit">保存</b-button>
      <b-button variant="light" @click="backToList" class="mx-4">戻る</b-button>
    </template>
  </b-card>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useShippingAddressesStore } from "@/stores/customer/shipping_addresses";
import { cloneDeep } from "lodash";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { onMounted } from "vue";
import useGraphql from "@/composables/useGraphql";
import { SharedGeneralsSelectOptionsType } from "@/types/shared/generals/SharedGeneralsSelectOptionsType";
import * as commonQueries from "@/graphql/queries/common";
import { useValidationStore } from "@/stores/form/validation";
import { addressDataSchemaFragment } from "@/composables/useCartValidation";
import FormAddress from "@/components/molecules/pages/cart/FormAddress.vue";

interface Props {
  type: string;
  backPath: string;
}

const props = defineProps<Props>();
const emit = defineEmits(["onSubmit"]);

const addressStore = useShippingAddressesStore();

addressStore.initNewAddress();

const validationStore = useValidationStore();

const {
  values: formValues,
  setValues: setFormValues,
  validate,
  errors,
} = useForm({
  validationSchema: yup.object({
    id: yup.string(),
    lockVersion: yup.number(),
    ...addressDataSchemaFragment,
  }),
  initialValues: {},
});

const fetchSelectOptions = () => {
  useGraphql<SharedGeneralsSelectOptionsType>(commonQueries.selectOptions, {
    keys: ["sexes", "prefectures"],
  }).then((data) => {
    addressStore.prefectures = data.prefectures;
  });
};
const onSubmit = async () => {
  validationStore.submitAttempts++;

  const result = await validate();

  if (!result.valid) return;

  addressStore.shippingAddressInfo = cloneDeep(formValues);
  emit("onSubmit");
};

onMounted(() => {
  fetchSelectOptions();
});

watch(
  () => addressStore.shippingAddressInfo,
  () => setFormValues(addressStore.shippingAddressInfo as any),
  { deep: true },
);

const backToList = () => {
  window.location.href = props.backPath;
};
</script>
