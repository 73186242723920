<template>
  <div v-if="baseInfo" class="wrapper d-flex flex-column flex-row-fluid header-fixed">
    <CustomerHeader />

    <div class="d-flex align-items-start container-xl">
      <CustomerSidemenu :active="active" />

      <div class="body d-flex flex-column flex-fill">
        <div class="toolbar mb-5 mb-lg-7">
          <UserBreadcrumb :breadcrumb="breadcrumb" />
        </div>

        <div class="content flex-column-fluid information-image">
          <slot></slot>

          <div class="footer py-4 text-center">
            <div class="text-dark order-2 order-md-1">
              <span class="text-muted fw-semibold me-1"> {{ baseInfo.copyright }} © </span>
              <span class="text-gray-800 text-hover-primary">Unicorn Cart</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import CustomerHeader from "@/components/organisms/layout/CustomerHeader.vue";
import CustomerSidemenu from "@/components/organisms/layout/CustomerSidemenu.vue";
import UserBreadcrumb from "@/components/molecules/common/layout/UserBreadcrumb.vue";

import { useBaseInfoStore } from "@/stores/base_info";
import { useAuthStore } from "@/stores/auth";

interface Props {
  active?: string;
  breadcrumb?: string;
}

defineProps<Props>();

const baseInfoStore = useBaseInfoStore();
const authStore = useAuthStore();

baseInfoStore.fetchBaseInfo();
if (authStore.isLoggedIn) authStore.fetchCustomerInfo();

const baseInfo = computed(() => baseInfoStore.baseInfo);
</script>

<style lang="scss" scoped>
.toolbar {
  background: none;
}

.body {
  padding: 0 30px;
  width: 100%;

  @media (max-width: 992px) {
    margin-top: 60px;
    padding: 0;
  }

  .content {
    padding: 0;
  }
}

.footer {
  background: none;
  margin-top: 40px;
}

@media (min-width: 992px) {
  .wrapper {
    padding-top: 110px;
  }
}
</style>
