<template>
  <b-card>
    <Notice>
      <p class="mb-0">
        <strong>必須項目</strong>
        「
        <strong>
          <span class="text-danger">※</span>
        </strong>
        」印は入力必須です。
      </p>
    </Notice>

    <draft-form
      class="mt-10"
      :contact-input="formValues"
      :contact-types="contactStore.contactTypes"
    ></draft-form>

    <div :key="Math.random()" class="mt-5">
      <FormValidator required float>
        <b-form-checkbox v-model="acceptTerm" rows="3">
          <a href="/shop/privacy" target="blank">プライバシーポリシー</a>
          <span class="ms-2">に同意</span></b-form-checkbox
        >
      </FormValidator>
    </div>

    <div class="mt-8">
      <div class="col-md-10 col-md-offset-2">
        <b-button @click="onSubmit()" variant="primary" class="btn-sm" :disabled="!acceptTerm">
          確認画面へ
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script setup lang="ts">
import { onMounted, watch, ref } from "vue";
import { useContactStore } from "@/stores/contacts";
import { useForm } from "vee-validate";
import { useValidationStore } from "@/stores/form/validation";
import { useAuthStore } from "@/stores/auth";
import { useCustomerStore } from "@/stores/customer/edit";
import * as yup from "yup";
import { addressDataSchemaFragment } from "@/composables/useCartValidation";
import { assign, cloneDeep, pick, keys } from "lodash";
import moment from "moment";
import DraftForm from "@/components/molecules/pages/contact/DraftForm.vue";

const contactStore = useContactStore();
const validationStore = useValidationStore();
const acceptTerm = ref(false);
const customerStore = useCustomerStore();

contactStore.selectOptions();

const authStore = useAuthStore();

const { values: formValues, validate } = useForm({
  validationSchema: yup.object({
    input: yup.object({
      ...pick(addressDataSchemaFragment, [
        "name01",
        "name02",
        "kana01",
        "kana02",
        "tel01",
        "tel02",
        "tel03",
      ]),
      content: yup.string().required(),
      contactTypeId: yup.string().required(),
    }),
    email: yup.string().required().email(),
  }),
  initialValues: contactStore.contactInput,
});

const initContactType = () => {
  if (formValues.input.contactTypeId) return;

  const firstOption: any = contactStore.contactTypes[0];
  formValues.input.contactTypeId = firstOption ? firstOption.value : null;
};

const currentURL = window.location.href;

const onSubmit = async () => {
  validationStore.submitAttempts++;

  const validateResult = await validate();
  if (!validateResult.valid) return;

  authStore.originalUrl = currentURL;
  const result = await contactStore.contactsValidate(formValues);

  if (!result) return;

  contactStore.timeStaging = 0;
  contactStore.contactInput = cloneDeep(formValues);

  window.location.href = "/shop/contact/confirm";
};

watch(() => contactStore.contactTypes, initContactType, { deep: true });

onMounted(async () => {
  if (authStore.isLoggedIn && !contactStore.confirming) {
    await authStore.fetchCustomerInfo();

    formValues.email = authStore.customer?.email ?? "";

    const input = pick(authStore.customer?.billingAddress, keys(formValues.input));

    assign(formValues.input, input);
  }

  contactStore.confirming = false;
});

onMounted(async () => {
  initContactType();

  if (contactStore.timeStaging && moment().unix() - contactStore.timeStaging > 60) {
    contactStore.$reset();
  }
});
</script>

<style lang="scss" scoped>
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #333;
}
</style>
