<template>
  <div>
    <FormAddress type="new" @onSubmit="create" :backPath="backPath"></FormAddress>
  </div>
</template>

<script lang="ts" setup>
import FormAddress from "@/components/molecules/pages/customer/ShippingAddress.vue";
import { useShippingAddressesStore } from "@/stores/customer/shipping_addresses";

const addressStore = useShippingAddressesStore();

const backPath = "/shop/customer/shipping_addresses"

const create = () => {
  addressStore.createShippingAddress().then(backToList);
};

const backToList = () => {
  window.location.href = backPath;
};
</script>
