<template>
  <div>
    <div class="d-flex align-items-center flex-shrink-0" v-if="authStore.token">
      <div class="d-flex align-items-center ms-1 position-relative" @click="showUserMenu = true">
        <div
          class="btn btn-flex align-items-center bg-hover-white bg-hover-opacity-10 py-2 px-2 px-md-3"
        >
          <div class="symbol symbol-30px symbol-md-40px">
            <img src="/assets/images/shop/avatar.png" />
          </div>
        </div>
        <div
          ref="menu"
          class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
          :class="{ show: showUserMenu }"
        >
          <div class="menu-item px-3">
            <div class="menu-content d-flex align-items-center px-3">
              <div class="symbol symbol-50px me-5">
                <img src="/assets/images/shop/avatar.png" />
              </div>
              <div class="d-flex flex-column w-75">
                <div class="fw-bold d-flex align-items-center fs-5">
                  {{ authStore.customer?.billingAddress.name01 }}
                  {{ authStore.customer?.billingAddress.name02 }}
                </div>
                <a href="#" class="fw-semibold text-muted text-hover-primary overflow-text fs-7">
                  {{ authStore.customer?.email }}
                </a>
              </div>
            </div>
          </div>
          <div class="separator my-2"></div>
          <div class="menu-item px-5">
            <a href="/shop/customer/edit" class="menu-link px-5">会員情報変更</a>
          </div>
          <div class="separator my-2"></div>
          <div class="menu-item px-5">
            <a href="#" class="menu-link px-5" @click="logoutModalShow = true">ログアウト</a>
          </div>
        </div>
      </div>

      <b-modal
        v-model="logoutModalShow"
        title="本当にログアウトしますか？"
        ok-title="はい"
        cancel-title="キャンセル"
        body-class="p-0"
        content-class="height-auto"
        centered
        @ok="logout"
      >
      </b-modal>
    </div>
    <div v-else>
      <a href="/shop/customers/sign_in" class="sign_in_btn">ログイン</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useCartStore } from "@/stores/cart";
import { onClickOutside } from "@vueuse/core";

const authStore = useAuthStore();

const cartStore = useCartStore();

const showUserMenu = ref(false);

const menu = ref(null);

const logoutModalShow = ref(false);

onClickOutside(menu, () => (showUserMenu.value = false));

const logout = () => {
  authStore.logout();
  cartStore.$reset();
  window.location.href = "/shop/customers/sign_in";
};
</script>

<style lang="scss" scoped>
.menu.show {
  z-index: 105;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  left: 0;
  top: 110%;
}

@media screen and (max-width: 1800px) {
  .menu.show {
    right: 0;
    left: unset;
  }
}

.sign_in_btn {
  color: white;
}

.overflow-text {
  word-wrap: break-word;
  max-width: 100%;
}
</style>

<style lang="scss">
.height-auto {
  height: auto;
}
</style>
