<template>
  <b-card class="mb-8">
    <template #header>
      <b-card-title>Paygent</b-card-title>
      <b-button variant="primary" size="sm" @click="paygentModalShow = true">新規作成</b-button>
    </template>

    <div v-if="paygentCards.length" class="row row-cols-1 row-cols-md-2 g-5">
      <div class="col" v-for="card in paygentCards" :key="card.id">
        <CreditCard
          :card="card"
          card-index-field="id"
          @delete="deletedPaygentCard"
          deleteable
        ></CreditCard>
      </div>
    </div>
    <div v-else>現在登録されているカードはありません</div>
  </b-card>

  <b-modal v-model="paygentModalShow" title="お支払い情報" body-class="p-0" hide-footer hide-header>
    <CardForm :card="card" @submitForm="addPaygentCard"></CardForm>
  </b-modal>
</template>

<script lang="ts" setup>
import { computed, inject, ref, reactive } from "vue";
import { Toaster } from "@meforma/vue-toaster";
import { CreditCardInput } from "@/types/input/customers/CardInput";
import { useCardsStore } from "@/stores/customer/cards";
import { linkCreditCard } from "@/composables/payment_cards/paygent_card";

import { PAYGENT_PAYMENT_METHOD_CODE } from "@/helpers/constants";

import CardForm from "@/components/molecules/pages/customer/cards/CardForm.vue";
import CreditCard from "@/components/molecules/common/shared/CreditCard.vue";

interface Props {
  paygentInfo: string;
}

const props = defineProps<Props>();
const card = reactive(new CreditCardInput());

const paygentModalShow = ref(false);

const cardsStore = useCardsStore();

const toast = inject<Toaster>("toast")!;

cardsStore.fetchPaygentCards();

const paygentCards = computed(() => cardsStore.paygentCards);

const addPaygentCard = () => {
  linkCreditCard(card, JSON.parse(props.paygentInfo), toast, cardsStore.createPaygentCards);

  paygentModalShow.value = false;
};

const deletedPaygentCard = (cardSeq: string | null) => {
  if (cardSeq) {
    cardsStore.deletePaygentCards(cardSeq);
  }
};
</script>
