<template>
  <b-card class="mb-9">
    <template #header>
      <div class="card-title">
        <h3>基本情報</h3>
      </div>
    </template>

    <div class="d-grid gap-5">
      <b-row>
        <b-col md="4" class="text-gray-600">注文番号</b-col>
        <b-col md="8">{{ order.number }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">小計</b-col>
        <b-col md="8">{{ order.subtotal }}円</b-col>
      </b-row>

      <template v-for="(item, index) in subtotals.target" :key="index">
        <b-row v-if="item.targetSubtotal.value">
          <b-col md="4" class="text-gray-600">{{ item.targetSubtotal.text }}</b-col>
          <b-col md="6">{{ item.targetSubtotal.value }}円</b-col>
        </b-row>
      </template>

      <b-row>
        <b-col md="4" class="text-gray-600">割引</b-col>
        <b-col md="8">
          <!-- {{ order.discount }}円 -->
          0円
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">ラッピング代金</b-col>
        <b-col md="8">0円</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">送料</b-col>
        <b-col md="8">{{ order.delivFee }}円</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">手数料</b-col>
        <b-col md="8">{{ order.charge }}円</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">消費税</b-col>
        <b-col md="8">{{ order.tax }}円</b-col>
      </b-row>

      <template v-for="(consumption, index) in consumptions.target" :key="index">
        <b-row v-if="consumption.targetConsumption.value">
          <b-col md="4" class="text-gray-600">{{ consumption.targetConsumption.text }}</b-col>
          <b-col md="8">{{ consumption.targetConsumption.value }}円</b-col>
        </b-row>
      </template>

      <b-row>
        <b-col md="4" class="text-gray-600">調整金額</b-col>
        <b-col md="8">{{ order.adjustmentAmount }}円</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">合計</b-col>
        <b-col md="8">{{ order.total }}円</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">支払い合計</b-col>
        <b-col md="8">{{ order.paymentTotal }}円</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">付与予定ポイント</b-col>
        <b-col md="8"></b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">支払い方法</b-col>
        <b-col md="8">{{ order.paymentMethod }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">分割回数</b-col>
        <b-col md="8">0</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">対応状況</b-col>
        <b-col md="8">{{ order.humanOrderState }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">決済状況</b-col>
        <b-col md="8">{{ order.paymentState }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">配送業者</b-col>
        <b-col md="8">{{ order.shippingCarrierName }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">配送伝票番号</b-col>
        <b-col md="8">{{ order.shippingSlip }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">発送予定日</b-col>
        <b-col md="8">{{ filters.fullDateTime(order.scheduledToBeShippedAt) }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">発送完了日</b-col>
        <b-col md="8">{{ filters.fullDateTime(order.shippedAt) }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">配送予定日</b-col>
        <b-col md="8">{{ filters.fullDateTime(order.scheduledToBeDeliveredAt) }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">お届け時間</b-col>
        <b-col md="8">{{ order.scheduledDeliveryTime }}</b-col>
      </b-row>

      <b-row>
        <b-col md="4" class="text-gray-600">購入日時</b-col>
        <b-col md="8">
          {{ filters.fullDateTime(order.orderedAt) }}
        </b-col>
      </b-row>
    </div>

    <template #footer>
      <div class="d-flex justify-content-between">
        <a :href="`/shop/customer/orders/`" class="btn btn-light">戻る</a>

        <b-button
          v-if="baseInfo?.orderReceiptDisplayFlg"
          variant="primary"
          @click="modalShow = true"
        >
          領収書発行
        </b-button>
      </div>
    </template>
  </b-card>

  <b-modal
    v-model="modalShow"
    :show="true"
    hide-header
    ok-title="OK"
    ok-variant="primary"
    cancel-title="取消"
    @ok="receiptRequest"
  >
    領収書は登録されているメールアドレス宛に送信されます。本当に実行しますか？
  </b-modal>
</template>

<script lang="ts" setup>
import filters from "@/composables/filters";
import { CustomersOrderType } from "@/types/customers/CustomersOrderType";
import * as orderQueries from "@/graphql/queries/customers/orders";
import useGraphql from "@/composables/useGraphql";
import { computed, ref } from "vue";
import { useBaseInfoStore } from "@/stores/base_info";
import { CustomersTargetSubtotalType } from "@/types/customers/CustomersTargetSubtotalType";
import { CustomersTargetConsumptionType } from "@/types/customers/CustomersTargetConsumptionsType";

interface Props {
  order: CustomersOrderType;
  subtotals: CustomersTargetSubtotalType;
  consumptions: CustomersTargetConsumptionType;
}

const props = defineProps<Props>();

const modalShow = ref(false);
const baseInfoStore = useBaseInfoStore();

const receiptRequest = () => {
  useGraphql(
    orderQueries.customersOrderReceiptRequest,
    { orderNumber: props.order.number },
    { toasted: true },
  );
};

baseInfoStore.fetchBaseInfo();

const baseInfo = computed(() => baseInfoStore.baseInfo);
</script>
