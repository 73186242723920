<template>
  <div>
    <div class="d-grid gap-5">
      <FormValidator
        label="お問い合わせ種別"
        float
        name="contact_type_id"
        client-name="input.contactTypeId"
      >
        <b-form-select v-model="contactInput.input.contactTypeId" :options="contactTypes">
        </b-form-select>
      </FormValidator>
      <FormValidator label="メールアドレス" name="email" required float>
        <b-form-input
          v-model="contactInput.email"
          placeholder="例) abc@abc.com"
          :disabled="!!authStore.token"
        />
      </FormValidator>
      <form-address
        v-if="contactInput.input"
        :addressData="contactInput.input"
        prefix="input"
        prefixNameValidator=""
        hide-address-section
      ></form-address>

      <FormValidator
        label="お問い合わせ内容"
        name="content"
        client-name="input.content"
        required
        float
      >
        <b-form-textarea v-model="contactInput.input.content" rows="6" />
      </FormValidator>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ContactInput } from "@/types/input/contactInput";
import { SharedGeneralsSelectOptionType } from "@/types/shared/generals/SharedGeneralsSelectOptionType";
import { useAuthStore } from "@/stores/auth";
import FormValidator from "@/components/molecules/common/form/FormValidator.vue";
import FormAddress from "@/components/molecules/pages/cart/FormAddress.vue";
const authStore = useAuthStore();

const props = defineProps<{
  contactInput: ContactInput;
  contactTypes: SharedGeneralsSelectOptionType[];
}>();
</script>

<style lang="scss" scoped>
:deep(.form-control:disabled) {
  background-color: #eff2f5 !important;
  opacity: 1;
}

.customer-layout {
  .form-select,
  .form-select:focus,
  textarea,
  textarea:focus {
    background-color: white;
  }
}
</style>
