import { defineStore, acceptHMRUpdate } from "pinia";
import useGraphql from "@/composables/useGraphql";
import * as authQueries from "@/graphql/queries/customers/auth";
import * as commonQueries from "@/graphql/queries/common";
import { CustomersCustomerType } from "@/types/customers/CustomersCustomerType";
import { SharedGeneralsSelectOptionType } from "@/types/shared/generals/SharedGeneralsSelectOptionType";
import { SharedGeneralsSelectOptionsType } from "@/types/shared/generals/SharedGeneralsSelectOptionsType";
import { CustomersUpdateInput } from "@/types/input/customers/UpdateInput";
import { CustomersUpdateType } from "@/types/customers/CustomersUpdateType";

type State = {
  customerInfo: CustomersUpdateInput;
  prefectures: SharedGeneralsSelectOptionType[];
  sexes: SharedGeneralsSelectOptionType[];
};

export const useCustomerStore = defineStore({
  id: "customer",

  state: (): State => ({
    customerInfo: new CustomersUpdateInput(),
    prefectures: [],
    sexes: [],
  }),

  actions: {
    async fetchCustomerInfo() {
      await useGraphql<SharedGeneralsSelectOptionsType>(commonQueries.selectOptions, {
        keys: ["sexes", "prefectures"],
      }).then((data) => {
        this.prefectures = data.prefectures;
        this.sexes = data.sexes;
      });

      await useGraphql<CustomersCustomerType>(authQueries.customerInfo).then((data) => {
        this.customerInfo.assignAttributes(data);
        this.customerInfo.billingAddressAttributes.assignAttributes(data.billingAddress);
      });
    },

    updateCustomer() {
      useGraphql<CustomersUpdateType>(
        authQueries.updateCustomer,
        { input: this.customerInfo },
        { toasted: true },
      ).then((data) => {
        this.customerInfo.assignAttributes(data.customer);
        this.customerInfo.billingAddressAttributes.assignAttributes(data.customer.billingAddress);
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCustomerStore, import.meta.hot));
}
